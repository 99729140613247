import {  useParams } from 'react-router-dom';
import {  useAppSelector } from '../store/store';
import { RoutePathNames } from '../helpers/constants';
import {  Toolbar, Button, useTheme } from '@mui/material';
import { Link,  } from 'react-router-dom';

function Navbar() {
    const theme = useTheme();
    //const appConfig = store.getState().appConfig.value;
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    //const path = window.location.pathname.replace('/', '');
    const { id } = useParams();

    // const activeLink = `border-b-2  border-[#cc0000] font-bold text-[#cc0000] p-2  `
    // const normalLink = ' p-2'
    const isActive = (path: string) => window.location.pathname.replace('/', '') === path;
    return (
        loggedOnUserProfile &&
        <div className=''>
            <div className='flex space-x-6'>
                {/*   <>
                    <NavLink to={'/' + RoutePathNames.SCENARIO_RUN_HISTORY} className={path === RoutePathNames.SCENARIO_RUN_HISTORY ? activeLink : path === `${RoutePathNames.SCENARIO_RUN}/${id}` ? activeLink : normalLink}>Scenario Run</NavLink>
                    <NavLink to={'/' + RoutePathNames.WELLS} className={({ isActive }) => isActive ? activeLink : normalLink}>Wells</NavLink>
                    {loggedOnUserProfile.is_admin === true &&
                    <>
                        <NavLink to={'/' + RoutePathNames.COMPRESSOR_SETTINGS} className={({ isActive }) => isActive ? activeLink : normalLink}>Compressor Settings</NavLink>
                        <NavLink to={'/' + RoutePathNames.TANKS} className={({ isActive }) => isActive ? activeLink : normalLink}>Tank Data</NavLink>
                        <NavLink to={'/' + RoutePathNames.USERS} className={({ isActive }) => isActive ? activeLink : normalLink}>User Management</NavLink>
                    </>
                }
                </> */}
                <>
                    <Toolbar>
                        <>
                            <Button
                                component={Link}
                                to={'/' + RoutePathNames.SCENARIO_RUN_HISTORY}
                                color="inherit"
                                sx={{
                                    textTransform:'capitalize',
                                    fontSize: '14px',
                                    fontWeight: isActive(RoutePathNames.SCENARIO_RUN_HISTORY) ? 'bold' : isActive(`${RoutePathNames.SCENARIO_RUN}/${id}`) ?  `bold` : 'medium',
                                    color: isActive(RoutePathNames.SCENARIO_RUN_HISTORY) ? theme.palette.secondary.main : isActive(`${RoutePathNames.SCENARIO_RUN}/${id}`) ? theme.palette.secondary.main :'inherit',
                                    borderRadius: 0,
                                   
                                    borderBottom: isActive(RoutePathNames.SCENARIO_RUN_HISTORY) ? `3px solid ${theme.palette.secondary.main}`: isActive(`${RoutePathNames.SCENARIO_RUN}/${id}`) ?  `3px solid ${theme.palette.secondary.main}` : 'none',
                                }}
                            >
                                Scenario Run
                            </Button>

                            <Button
                                component={Link}
                                to={'/' + RoutePathNames.WELLS}
                                color="inherit"
                                sx={{
                                    textTransform:'capitalize',
                                    fontSize: '14px',
                                    fontWeight: isActive(RoutePathNames.WELLS) ? 'bold' : 'medium',
                                    color: isActive(RoutePathNames.WELLS) ? theme.palette.secondary.main : 'inherit',
                                    borderRadius: 0,
                                    
                                    borderBottom: isActive(RoutePathNames.WELLS) ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                }}
                            >
                                Wells
                            </Button>
                            {loggedOnUserProfile.is_admin === true &&
                                <>
                                    <Button
                                        component={Link}
                                        to={'/' + RoutePathNames.COMPRESSOR_SETTINGS}
                                        color="inherit"
                                        sx={{
                                            textTransform:'capitalize',
                                            fontSize: '14px',
                                            fontWeight: isActive(RoutePathNames.COMPRESSOR_SETTINGS) ? 800 : 'medium',
                                            color: isActive(RoutePathNames.COMPRESSOR_SETTINGS) ? theme.palette.secondary.main : 'inherit',
                                            borderRadius: 0,
                                           
                                            borderBottom: isActive(RoutePathNames.COMPRESSOR_SETTINGS) ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                        }}
                                    >
                                        Compressor Settings
                                    </Button>
                                    <Button
                                        component={Link}
                                        to={'/' + RoutePathNames.TANKS}
                                        color="inherit"
                                        sx={{
                                            textTransform:'capitalize',
                                            fontSize: '14px',
                                            fontWeight: isActive(RoutePathNames.TANKS) ? 'bold' : 'medium',
                                            color: isActive(RoutePathNames.TANKS) ? theme.palette.secondary.main : 'inherit',
                                            borderRadius: 0,
                                            
                                            borderBottom: isActive(RoutePathNames.TANKS) ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                        }}
                                    >
                                        Tank Data
                                    </Button>
                                    <Button
                                        component={Link}
                                        to={'/' + RoutePathNames.USERS}
                                        color="inherit"
                                        sx={{
                                            textTransform:'capitalize',
                                            fontSize: '14px',
                                            fontWeight: isActive(RoutePathNames.USERS) ? 'bold' : 'medium',
                                            color: isActive(RoutePathNames.USERS) ? theme.palette.secondary.main : 'inherit',
                                            borderRadius: 0,
                                            
                                            borderBottom: isActive(RoutePathNames.USERS) ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                        }}
                                    >
                                        User Management
                                    </Button>
                                </>
                            }
                        </>

                    </Toolbar>
                </>
            </div>
        </div>

    )
}

export default Navbar