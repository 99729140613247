import { enqueueSnackbar } from "notistack";
import { CmdbUser, ServerAPIResponse, TepngUser } from "../helpers/interfaces";
import { showBackDrop, callServerAPI } from '../helpers/utils'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllUserProfiles = async () => {
    return await new Promise<TepngUser[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading User Profiles"));
        callServerAPI<ServerAPIResponse<TepngUser[]>>('GET', '/api/tepng-user/get-tepng-users', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}
export const getAllCMDBUsers = async () => {
    return await new Promise<CmdbUser[]>(async (resolve, reject) => {
        callServerAPI<ServerAPIResponse<CmdbUser[]>>('GET', '/api/tepng-user/get-cmdb-users', null, true)
            .then(response => {
                resolve(response.data.data)
            })
    })
}
export async function deleteUserProfile(email: string) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete User Profile"));
        await callServerAPI<any>('DELETE', `/api/tepng-user/delete-tepng-user?email=${email}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("User Profile Deleted", { variant: 'success' });
                resolve()
            })
    })
}


export async function createUserProfile(email: string, is_admin: boolean) {
    return await new Promise<TepngUser>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new User Profile"));
        callServerAPI<ServerAPIResponse<TepngUser>>('POST', `/api/tepng-user/add-tepng-user?email=${email}&is_admin=${is_admin}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("User Profile Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}


export async function updateUserProfile(email: string, is_admin: boolean) {
    return await new Promise<TepngUser>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating User Profile"));
        callServerAPI<ServerAPIResponse<TepngUser>>('PUT', `/api/tepng-user/update-tepng-user?email=${email}&is_admin=${is_admin}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("User Profile Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}