import React, { useEffect } from 'react'
import { ScenarioRun, Tank } from '../../helpers/interfaces'
import { Box, Paper, useTheme } from '@mui/material'
import moment from 'moment'
import BarChart from '../barchart/BarChart'


interface Props {
    scenarioRun: ScenarioRun
    includedTanks: Tank[]
}

function ExecutiveSummaryStorageProfile(props: Props) {
    const [barStorageProfileSpareCapacityData, setBarStorageProfileSpareCapacityData] = React.useState<number[]>([])
    const [barActualStorageSpareCapacityData, setBarActualStorageSpareCapacityData] = React.useState<number[]>([])
    const [barChartDate, setBarChartDate] = React.useState<string[]>([])
    const theme = useTheme();
    let scenarioId = props.scenarioRun.recommended_scenario_id

    useEffect(() => {
        
        let scenarioToView = props.scenarioRun.scenarios[scenarioId]

        let barSpareCapacityArray: number[] = []
        let barTankCapacityArray: number[] = []
        let barDate: string[] = []
        // console.log(scenarioToView?.storage_profiles)
        scenarioToView?.storage_profiles.forEach((e) => {

            let tankLevelCapacity = props.includedTanks.reduce((previousSum, b) => {
                let b_capacity = b.tank_level_history?.find(c => c.timestamp === e.date_time)?.storage_spare_capacity ?? 0
                return previousSum + b_capacity

            }, 0)

            //BarChart
            let date = moment(new Date(e.date_time)).format('MMM D')
            console.log(date)
            if (!barDate.includes(date)) {
                barDate.push(date)
                barSpareCapacityArray.push(Math.ceil(e.storage_spare_capacity))
                barTankCapacityArray.push(Math.ceil(tankLevelCapacity))

            }

        })

        setBarStorageProfileSpareCapacityData(barSpareCapacityArray)
        setBarActualStorageSpareCapacityData(barTankCapacityArray)
        setBarChartDate(barDate)
        // eslint-disable-next-line
    }, [props.scenarioRun])

    let barDatasets = [
        {
            label: 'Forcasted Storage Profile ',
            data: [...barStorageProfileSpareCapacityData],
            borderColor: '#DA423F',
        },
        {
            label: 'Actual Storage Profile',
            data: [...barActualStorageSpareCapacityData],
            borderColor: '#4283D1',
        },
    ];
    return (
        props.scenarioRun &&
        <>
            <Paper>

                <Box mt="10px" p='1.0rem' gap="20px">
                    <Box style={{ textAlign: 'center', color: `${theme.palette.text.primary}`, fontWeight: 'bold' }}>
                       Storage Profile in Days ( {props.scenarioRun.scenarios[props.scenarioRun.recommended_scenario_id]?.name})
                    </Box>
                    <BarChart datasets={barDatasets} labels={barChartDate} />
                </Box>

            </Paper>
        </>
    )
}

export default ExecutiveSummaryStorageProfile
