import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CmdbUser } from '../helpers/interfaces';

interface CmdbUsersState {
    value: CmdbUser[]
}

export const cmdbUsers = createSlice({
    name: 'cmdbUsers',
    initialState: {
        value: [],
    } as CmdbUsersState,
    reducers: {
        setCmdbUsers: (state, action: PayloadAction<CmdbUser[]>) => {
            state.value = action.payload
        },
    },
})

export const { setCmdbUsers } = cmdbUsers.actions;
export default cmdbUsers.reducer;