import { Add, Edit } from '@mui/icons-material'
import { Box, Button, Grid, MenuItem, Pagination, Paper, Stack ,Table, TableBody, TableContainer, TableHead, useTheme,} from '@mui/material'
import React from 'react'
import { useState, useEffect } from "react";
import { Tank } from '../helpers/interfaces';
import { FormEditMode ,TankType} from '../helpers/constants';
import { getAllTankSummaries } from '../apiServices/TankManagemetApi';
import CreateEditTanks from '../components/tanks/CreateEditTanks';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../layouts/theme';



function TankList() {
    const theme = useTheme();
    const [tanks, setTanks] = useState<Tank[]>([])
    const [filteredTanks, setFilteredTanks] = useState<Tank[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(5);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<Tank[]>([])
    const [openTankForm, setOpenTankForm] = React.useState(false);
    const [tankEditMode, setTankEditMode] = React.useState(FormEditMode.CREATE);
    const [tankToEdit, setTankToEdit] = useState<string>("");

    const handleOpenTankForm = () => {

        setTankEditMode(FormEditMode.CREATE);
        setOpenTankForm(true);
    };
    useEffect(() => {
        getAllTankSummaries().then(tanks => setTanks(tanks))
    }, [])
    useEffect(() => {
        setFilteredTanks(tanks.filter(e =>
            (searchQuery === "" || e.name.toLowerCase().includes(searchQuery.toLowerCase()) || e.type.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, tanks])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredTanks.length / recordsPerPage))
    }, [recordsPerPage, filteredTanks])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredTanks.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredTanks])


    const handleEdit = (tank: Tank) => {
        setTankToEdit(tank.id)
        setTankEditMode(FormEditMode.EDIT);
        setOpenTankForm(true);
    }

    return (
        <Box m="1.0rem 1.0rem">
            <div className=' font-bold text-lg' style={{color:theme.palette.text.secondary}} >Tank List</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        <div className=" flex flex-row ">
                            <div className=' flex w-2/3 '>
                                <CustomTextField size="small" id="outlined-basic" placeholder="Search by Name, Type..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} variant="outlined" sx={{ width: '50%' }} /> &nbsp;

                            </div>
                            <div style={{ flexGrow: 1 }} />

                            <Button color='secondary' variant="contained" style={{ textTransform: 'none', fontSize: 14, }} onClick={handleOpenTankForm}><Add />&nbsp;Create New Tank</Button>
                        </div>
                        <br />
                        <TableContainer className="shadow-md sm:rounded-lg">
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                   
                                        <StyledTableCell> SN</StyledTableCell>
                                        <StyledTableCell>Tank Name</StyledTableCell>
                                        <StyledTableCell>Tank Type</StyledTableCell>
                                        <StyledTableCell>Tank Capacity (bbls)</StyledTableCell>
                                        <StyledTableCell>Volume per height of tank (bbls/mm)</StyledTableCell>
                                        <StyledTableCell>LAH (%)</StyledTableCell>
                                        <StyledTableCell>Tank Level PI Tag</StyledTableCell>
                                        <StyledTableCell>Tank Level (%)</StyledTableCell>
                                        <StyledTableCell> Max Oil Thickness (m)</StyledTableCell>
                                        <StyledTableCell>Oil Thickness PI Tag</StyledTableCell>
                                        <StyledTableCell> Oil Thickness (m)</StyledTableCell>
                                        <StyledTableCell>Action​</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageRecords.map((item, index) => {
                                        return <StyledTableRow key={index}>

                                            <StyledTableCell>{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                            <StyledTableCell>{item.name}</StyledTableCell>
                                            <StyledTableCell>{item.type}</StyledTableCell>
                                            <StyledTableCell>{item.capacity}</StyledTableCell>
                                            <StyledTableCell>{item.volume_per_height}</StyledTableCell>
                                            <StyledTableCell>{item.lah}</StyledTableCell>
                                            <StyledTableCell>{item.tank_level_pi_tag}</StyledTableCell>
                                            <StyledTableCell>{(item.tank_level_percent).toFixed(4)}</StyledTableCell>
                                            <StyledTableCell>{item.type === TankType.WASH_TANK? item.max_oil_thickness: 'N/A'}</StyledTableCell>
                                            <StyledTableCell>{item.type === TankType.WASH_TANK? item.oil_thickness_pi_tag:'N/A'}</StyledTableCell>
                                            <StyledTableCell>{item.type === TankType.WASH_TANK? (item.oil_thickness / 1000).toFixed(3) : 'N/A'}</StyledTableCell>
                                            
                                            <StyledTableCell >
                                                <Button variant='outlined' color='info' size='small' onClick={() => { handleEdit(item) }}  ><Edit /> Edit</Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    })}

                                    {currentPageRecords.length === 0 &&
                                        <tr>
                                            <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                        </tr>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />

                        <nav>
                            <div className=" flex flex-row ">
                                <div className=" flex items-start ">
                                    <div className="px-6 py-2">Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} >
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                        </CustomTextField>
                                    </Grid>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <Stack spacing={2}>
                                    <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>

                            </div>
                            <br />


                        </nav>

                        {openTankForm &&
                            <CreateEditTanks tankToEdit={tankToEdit} getAllTanks={getAllTankSummaries} setFormEditMode={setTankEditMode} formEditMode={tankEditMode} setTanks={setTanks} tanks={tanks} openTankForm={openTankForm} setOpenTankForm={setOpenTankForm} />
                        }
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default TankList