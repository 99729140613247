import { Box, Button, Grid, MenuItem, Pagination, Paper, Stack, Table, TableBody, TableContainer, TableHead, useTheme, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/store';
import { uploadWellsExcelFile } from '../apiServices/WellsApi';
import { setWells } from '../store/wells';
import { Well } from '../helpers/interfaces';
import { formatDate } from '../helpers/utils';
import { FormEditMode, Status, WellType } from '../helpers/constants';
import { Download, Edit, Upload, WaterDrop } from '@mui/icons-material';
import UpdateWellStatus from '../components/wells/UpdateWellStatus';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../layouts/theme';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const statusOptions = [{ value: Status.ALWAYS_OPEN, label: 'Always Open' }, { value: Status.CLOSED, label: 'Closed' }, { value: Status.OPEN, label: 'Open' }]
const wellTypeOptions = [{ value: WellType.OIL, label: ' OIL' }, { value: WellType.GAS, label: 'GAS' }]


function WellList() {
    const theme = useTheme();
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const dispatch = useAppDispatch();
    const wells = useAppSelector(state => state.wells.value)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(60);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<Well[]>([])
    const [openWellUpdateForm, setOpenWellUpdateForm] = React.useState(false);
    const [wellEditMode, setWellEditMode] = React.useState(FormEditMode.EDIT);
    const [wellToEdit, setWellToEdit] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState('All');
    const [typeFilter, setTypeFilter] = useState('All');
    const [filteredWells, setFilteredWells] = useState<Well[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [sortConfig, setSortConfig] = useState<{ column: keyof Well | null; direction: 'ascending' | 'descending' | null }>({
        column: null,
        direction: null,
    });

    useEffect(() => {
        if (wells !== null) {
            setNumOfPages(Math.ceil(filteredWells.length / recordsPerPage))
        }
    }, [recordsPerPage, filteredWells, wells])

    useEffect(() => {
        if (wells !== null) {
            const lastIndex = currentPage * recordsPerPage;
            const firstIndex = lastIndex - recordsPerPage;
            setCurrentPageRecords(filteredWells.slice(firstIndex, lastIndex))
        }
    }, [currentPage, recordsPerPage, filteredWells, wells])


    useEffect(() => {
        setFilteredWells(wells.filter(e =>
            (statusFilter === "All" || e.status.toLowerCase() === statusFilter.toLowerCase()) &&
            (typeFilter === "All" || e.type.toLowerCase() === typeFilter.toLowerCase()) &&
            (searchQuery === "" || e.name.toLowerCase().includes(searchQuery.toLowerCase()) || e.header.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, statusFilter, typeFilter, wells])

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist(); //important to persist event so we can reset the value of the input file after an upload
        if (e.target.files == null || e.target.files.length === 0) return
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        uploadWellsExcelFile(formData).then(wells => {
            dispatch(setWells(wells))
        })
        e.target.value = '' //important to persist event so we can reset the value of the input file after an upload
    }

    const handleEdit = (well: Well) => {
        setWellToEdit(well.id)
        setOpenWellUpdateForm(true);
    }
    const handleWISTemplateDownload = () => {
        window.location.href = '../../wisDataTemplate.xlsx';
    };

    const handleSort = (column: keyof Well) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.column === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ column, direction });

        const sortedData = [...currentPageRecords].sort((a, b) => {
            if (a[column] < b[column]) return direction === 'ascending' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setCurrentPageRecords(sortedData);
    };
    return (
        loggedOnUserProfile &&
        <Box m="1.0rem 1.0rem">
            <div className=' font-bold text-lg' style={{ color: theme.palette.text.secondary }}>Well Data</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        {
                            <>
                                <div className='flex' style={{ border: '1px solid #bbd3e9', padding: '10px', alignItems: 'center' }}>
                                    <div className='relative w-3/5  flex flex-col '>
                                        <p> <em className='text-[#CA092C] font-bold'>Note:</em> This data was uploaded on {formatDate(wells[0]?.created_at, 'date')}. Click the button to upload new data</p>
                                        <p><i>Supported formats: Excel </i></p>
                                    </div>
                                    {loggedOnUserProfile.is_admin === true &&
                                        <>
                                            <div style={{ flexGrow: 1 }} />
                                            <div style={{ float: 'right' }} >
                                                <Button style={{ textTransform: 'none', fontSize: 14, }} variant='outlined' color='success' component="label" onClick={handleWISTemplateDownload}> <Download /> &nbsp;&nbsp;Download WIS Template</Button>
                                                &nbsp;
                                                <Button style={{ textTransform: 'none', fontSize: 14, }} variant='contained' color='secondary' component="label"><Upload /> &nbsp;&nbsp;&nbsp;&nbsp;Upload WIS Data
                                                    <input hidden type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange} />
                                                </Button>
                                            </div>
                                        </>
                                    }

                                </div>
                                <br />
                                <div className=" flex flex-row ">
                                    <div className='column1' >
                                        <div>Search</div>
                                        <CustomTextField fullWidth size="small" id="fullWidth" variant="outlined" placeholder="Search by Well Name & Well Header" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} /> &nbsp;

                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className='column1' >
                                        <span>Status Filter</span>
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                            <MenuItem value="All">All Status</MenuItem >
                                            {statusOptions.map((status, index) => {
                                                return <MenuItem key={index} value={status.value}>{status.label}</MenuItem >
                                            })}

                                        </CustomTextField>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className='column1' >
                                        <span>Type Filter</span>
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="typeFilter" value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
                                            <MenuItem value="All">All</MenuItem >
                                            {wellTypeOptions.map((type, index) => {
                                                return <MenuItem key={index} value={type.value}>{type.label}</MenuItem >
                                            })}

                                        </CustomTextField>
                                    </div>
                                </div>
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <TableContainer className="shadow-md sm:rounded-lg">
                                        <Table className='summary overflow-x-auto table-auto' size="small" aria-label="a dense table"
                                            aria-labelledby="tableTitle"
                                        >
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '70px' }}>Action</StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px' }}>
                                                        SN
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px' }} onClick={() => handleSort('name')} >
                                                        Name{sortConfig.column === 'name' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}

                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('header')}  >
                                                        Header {sortConfig.column === 'header' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('type')}>
                                                        Type {sortConfig.column === 'type' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('status')}>
                                                        Status {sortConfig.column === 'status' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('oil')} >
                                                        <WaterDrop style={{ color: `${theme.palette.success.main}`, fontSize: '16px' }} /> Oil  <br /> (BOPD){sortConfig.column === 'oil' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell >
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('water')} >
                                                        <WaterDrop style={{ color: `${theme.palette.info.main}`, fontSize: '16px' }} />   Water <br /> (BWPD){sortConfig.column === 'water' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '210px' }} onClick={() => handleSort('gas')} >

                                                        <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />  Gas<br />(KSCMD){sortConfig.column === 'gas' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}

                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '210px' }} onClick={() => handleSort('gas_lift')} >
                                                        <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />  Gas Lift <br /> (KSCMD){sortConfig.column === 'gas_lift' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('condensate')}>
                                                        <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} /> Cond <br /> (BCPD){sortConfig.column === 'condensate' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('mca')} >MCA <br /> (Y/N){sortConfig.column === 'mca' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('bsw')} > BSW{sortConfig.column === 'bsw' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}</StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('gor')}>GOR {sortConfig.column === 'gor' ? (sortConfig.direction === 'ascending' ?  <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: '12px', minWidth: '200px' }} onClick={() => handleSort('gcr')} >GCR {sortConfig.column === 'gcr' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon style={{fontSize:'14px'}} /> : <ArrowDownwardIcon style={{fontSize:'14px'}}/>) : <SwapVertIcon />}

                                                    </StyledTableCell>



                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentPageRecords.map((item, index) => {
                                                    return <StyledTableRow key={index}>
                                                        <StyledTableCell> <span><Edit titleAccess='Edit' color='info' onClick={() => { handleEdit(item) }} /> </span></StyledTableCell>
                                                        <StyledTableCell >{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                                        <StyledTableCell >{item.name}</StyledTableCell>
                                                        <StyledTableCell >{item.header}</StyledTableCell>
                                                        <StyledTableCell >{item.type}</StyledTableCell>
                                                        <StyledTableCell style={{ background: item.status === Status.OPEN ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} ` }}>{item.status}</StyledTableCell>
                                                        <StyledTableCell>{item.oil}</StyledTableCell>
                                                        <StyledTableCell>{item.water}</StyledTableCell>
                                                        <StyledTableCell>{item.gas}</StyledTableCell>
                                                        <StyledTableCell>{item.gas_lift}</StyledTableCell>
                                                        <StyledTableCell>{item.condensate}</StyledTableCell>
                                                        <StyledTableCell>{item.mca}</StyledTableCell>
                                                        <StyledTableCell>{item.gor}</StyledTableCell>
                                                        <StyledTableCell>{item.bsw}</StyledTableCell>
                                                        <StyledTableCell>{item.gcr}</StyledTableCell>



                                                    </StyledTableRow>
                                                })}

                                                {currentPageRecords.length === 0 &&
                                                    <tr>
                                                        <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                                    </tr>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br />

                                    <nav>
                                        <div className=" flex flex-row ">
                                            <div className=" flex items-start ">
                                                <div className="px-6 py-2">Rows per page</div>&nbsp;
                                                < Grid item xs={12} lg={3} >
                                                    <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >

                                                        <MenuItem value="60">60</MenuItem >
                                                        <MenuItem value="120">120</MenuItem >
                                                        <MenuItem value="200">200</MenuItem >
                                                        <MenuItem value="255">255</MenuItem >
                                                        <MenuItem value="350">350</MenuItem >
                                                        <MenuItem value="500">500</MenuItem >


                                                    </CustomTextField>
                                                </Grid>
                                            </div>
                                            <div style={{ flexGrow: 1 }} />
                                            <Stack spacing={2}>
                                                <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                                    onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                            </Stack>

                                        </div>
                                        <br />


                                    </nav>

                                </div>
                            </>}

                        {openWellUpdateForm &&
                            <UpdateWellStatus wellToEdit={wellToEdit} setFormEditMode={setWellEditMode} formEditMode={wellEditMode} wells={wells} openWellUpdateForm={openWellUpdateForm} setOpenWellUpdateForm={setOpenWellUpdateForm} />
                        }
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default WellList