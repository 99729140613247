import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccessToken } from '../helpers/interfaces';

interface AccessTokenState {
    value: AccessToken | null
}

export const accessToken = createSlice({
    name: 'accessToken',
    initialState: {
        value: null,
    } as AccessTokenState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<AccessToken | null>) => {
            state.value = action.payload
        },
    },
})

export const { setAccessToken } = accessToken.actions;
export default accessToken.reducer;