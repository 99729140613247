import { Button, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomDialog from '../CustomDialogue'
import { Well } from '../../helpers/interfaces'
import { Add } from '@mui/icons-material'
import { Status, WellHeader } from '../../helpers/constants'
import { useAppDispatch } from '../../store/store'
import { updateWellStatusHeader } from '../../apiServices/WellsApi'
import { setWells } from '../../store/wells'
import { CustomTextField } from '../../layouts/theme'

interface Props {
    setFormEditMode: React.Dispatch<React.SetStateAction<number>>
    formEditMode: number
    wells: Well[]
    wellToEdit: string
    openWellUpdateForm: boolean
    setOpenWellUpdateForm: React.Dispatch<React.SetStateAction<boolean>>
}

const statusOptions = [{ value: Status.ALWAYS_OPEN, label: 'Always Open' }, { value: Status.CLOSED, label: 'Closed' }, { value: Status.OPEN, label: 'Open' }]
const headerOptions = [{ value: WellHeader.LLP, label: 'LLP' }, { value: WellHeader.LP, label: 'LP' }, { value: WellHeader.MP, label: 'MP' },{ value: WellHeader.HP, label: 'HP' }]

function UpdateWellStatus(props: Props) {
    const dispatch = useAppDispatch();
    const [wellStatus, setwellStatus] = useState<Status>(Status.OPEN);
    const [wellName, setWellName] = useState<string>("");
    const [wellHeader, setWellHeader] = useState<WellHeader>(WellHeader.HP);

    useEffect(() => {
        var well = props.wells.find(e => e.id === props.wellToEdit)
        if (!well) return
        setwellStatus(well.status)
        setWellName(well.name)
        setWellHeader(well.header)
    }, [props.wellToEdit, props.wells])

    function updateWell() {
        updateWellStatusHeader(props.wellToEdit, wellStatus, wellHeader).then(wells => {
            props.setOpenWellUpdateForm(false);
            var _wells: Well[] = props.wells.map(e => e.id === props.wellToEdit ? { ...e, status: wellStatus, header: wellHeader, updated_at: new Date() } : e)
            dispatch(setWells(_wells))
        })
    }
    return (
        <>
            <CustomDialog dialogTitle='Update Well Status | Header' setOpenDialog={props.setOpenWellUpdateForm} openDialog={props.openWellUpdateForm}>

                {wellStatus && <form>
                    <div>
                        <div >
                            <label className='title-label'> Well Name </label>
                        </div>
                        <div className='col-12'>
                            <CustomTextField fullWidth size="small" variant="outlined" value={wellName} disabled />

                        </div>
                    </div>
                    <br />
                    <div >
                        <label className='title-label'>Select Well Status </label>
                    </div>
                    <div className='col-12'>
                        <CustomTextField fullWidth size="small" select variant="outlined" id="status" value={wellStatus} onChange={(e) => setwellStatus(e.target.value as Status)}>
                            {statusOptions.map((wellStatus, index) => {
                                return <MenuItem key={index} value={wellStatus.value}>{wellStatus.label}</MenuItem >
                            })}
                        </CustomTextField>
                    </div>
                    <br />
                    <div >
                        <label className='title-label'>Select Well Header</label>
                    </div>
                    <div className='col-12'>
                        <CustomTextField fullWidth size="small" select variant="outlined" id="status" value={wellHeader} onChange={(e) => setWellHeader(e.target.value as WellHeader)}>
                            {headerOptions.map((header, index) => {
                                return <MenuItem key={index} value={header.value}>{header.label}</MenuItem >
                            })}
                        </CustomTextField>
                    </div>


                    <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                        <Button variant='contained' color='secondary' onClick={updateWell}><Add />&nbsp; Update Well Status | Header</Button>
                    </div>
                </form >
                }
            </CustomDialog>
        </>
    )
}

export default UpdateWellStatus