import React, { } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';

interface Props {
    dialogTitle: string
    buttonTitle: string
    buttonFunction: () => void
    openDialog: boolean
    children?: React.ReactElement
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>

}
function OverviewCustomDialog(props: Props) {
    return (
        <>
            <Dialog fullWidth maxWidth='md' open={props.openDialog} style={{ height: '100%' }} >
                <Stack direction='row' >
                    <DialogTitle style={{ fontSize: 14, fontWeight: 'bolder', width: '50%' }} > {props.dialogTitle}</DialogTitle>
                    <DialogActions style={{ width: '50%' }}>
                        <Button variant='contained' color='secondary' onClick={props.buttonFunction}>
                            {props.buttonTitle}
                        </Button>
                        <Button variant='contained' color="info" style={{ marginRight: 20 }} onClick={() => props.setOpenDialog(false)}>Close</Button>

                    </DialogActions>
                </Stack>
                <DialogContent>
                    {props.children}
                    <br />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default OverviewCustomDialog