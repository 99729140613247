import { configureStore } from "@reduxjs/toolkit";
import loggedOnUserProfile from "./loggedOnUserProfile";
import accessToken from "./accessToken";
import serverGlobalSettings from "./serverGlobalSettings";
import appConfig from "./appConfig";
import { useDispatch, useSelector } from 'react-redux'
import cmdbUsers from "./cmdbUsers";
import wells from "./wells";

export const store = configureStore({
    reducer: {
        loggedOnUserProfile: loggedOnUserProfile,
        cmdbUsers: cmdbUsers,
        wells: wells,
        appConfig: appConfig,
        accessToken: accessToken,
        serverGlobalSettings: serverGlobalSettings,
    },
})

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()