import React from 'react'
import { Outlet } from "react-router-dom";
import Header from '../components/Header'


function MainLayout() {
  return (
    <div >
      <div  >
        <Header />
      </div>
      <div style={{ marginTop: 134 }}>
        <Outlet />
      </div>
    </div>

  )
}

export default MainLayout