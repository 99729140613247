import { Box, Button, Paper, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { CompressorSettings } from '../helpers/interfaces'
import { UpdateCompressorSettings, getCompressorSettings } from '../apiServices/CompressorSettingsApi'
import { Add } from '@mui/icons-material'
import { emptyCompressorSettings } from '../helpers/constants'
import { CustomTextField } from '../layouts/theme'


function ViewCompressorSettings() {
    const theme = useTheme();
    const [compressorSettings, setCompressorSettings] = useState<CompressorSettings>(emptyCompressorSettings)

    useEffect(() => {
        getCompressorSettings().then(settings => setCompressorSettings(settings))
    }, [])

    const updateDataFieldValue = (name: string, value: any) => {
        setCompressorSettings(compressorSettings => ({ ...compressorSettings, [name]: value }))
    }
    function updateSettings() {
        return new Promise<void>((resolve, reject) => {
            UpdateCompressorSettings(compressorSettings).then(result => {
                getCompressorSettings().then(settings => setCompressorSettings(settings))
                resolve()
            })
        })

    }
    return (
        compressorSettings &&
        <Box m="1.0rem 1.0rem">
            <div className=' font-bold   text-lg' style={{color:theme.palette.text.secondary}} >Compressor Settings</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        <form>

                            <div className='row'>
                                <div className='column1'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>No of LP Compressors </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='num_lp_compressors' placeholder="No of LP Compressors" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.num_lp_compressors} />
                                        </div>
                                    </div>
                                </div>
                                &nbsp;&nbsp;&nbsp;

                                <div className='column2'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>No of HP Compressors </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='num_hp_compressors' placeholder="No of HP Compressors" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.num_hp_compressors} />
                                        </div>
                                    </div>


                                </div>
                                &nbsp;&nbsp;&nbsp;

                                <div className='column3'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>No of MP Compressors </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='num_mp_compressors' placeholder="No of MP Compressors" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.num_mp_compressors} />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='column1'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Min daily Gas production to run 1 LP (Ksm3/d) </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='min_daily_gas_production_to_run_1_lp' placeholder="Min daily Gas production to run 1 LP (Ksm3/d)" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.min_daily_gas_production_to_run_1_lp} />
                                        </div>
                                    </div>
                                </div>
                                &nbsp;&nbsp;&nbsp;

                                <div className='column2'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Min daily Gas production to run 1HP (Ksm3/d) </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='min_daily_gas_production_to_run_1_hp' placeholder="Min daily Gas production to run 1HP (Ksm3/d)" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.min_daily_gas_production_to_run_1_hp} />
                                        </div>
                                    </div>


                                </div>
                                &nbsp;&nbsp;&nbsp;

                                <div className='column3'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Min daily Gas production to run 1 MP (Ksm3/d)​ </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='min_daily_gas_production_to_run_1_mp' placeholder="No of MP Compressors" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.min_daily_gas_production_to_run_1_mp} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className='row'>
                                <div className='column1'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Min daily Gas production to run 2 LP (Ksm3/d) </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField type='number' fullWidth size="small" id='min_daily_gas_production_to_run_2_lp' placeholder="Min daily Gas production to run 2 LP (Ksm3/d)" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.min_daily_gas_production_to_run_2_lp} />
                                        </div>
                                    </div>
                                </div>
                                &nbsp;&nbsp;&nbsp;

                                <div className='column2'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Min daily Gas production to run 2 HP (Ksm3/d)​ </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField variant="outlined" type='number' fullWidth size="small" id='min_daily_gas_production_to_run_2_hp' placeholder=" Min daily Gas production to run 2 HP (Ksm3/d)​" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={compressorSettings.min_daily_gas_production_to_run_2_hp} />
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <br />

                            <div className="" style={{ float: 'right' }}>

                                <Button color='secondary' variant="contained" style={{ textTransform: 'none', fontSize: 14, }} onClick={updateSettings}><Add />&nbsp;Update Compressor Settings </Button>
                            </div>
                            <br />
                            <br />
                        </form>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default ViewCompressorSettings