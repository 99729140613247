import { enqueueSnackbar } from "notistack";
import {  CompressorSettings, ServerAPIResponse } from "../helpers/interfaces";
import { showBackDrop, callServerAPI } from '../helpers/utils'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getCompressorSettings = async () => {
    return await new Promise<CompressorSettings>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Compressor Settings"));
        callServerAPI<ServerAPIResponse<CompressorSettings>>('GET', '/api/compressor-settings/get-compressor-settings', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export const UpdateCompressorSettings = async (compressorData:CompressorSettings) => {
    return await new Promise<CompressorSettings>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating  Compressor Settings"));
        callServerAPI<ServerAPIResponse<CompressorSettings>>('POST', '/api/compressor-settings/update-compressor-settings', compressorData, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Compressor Settings  Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}