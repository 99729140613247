import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


// Register required components and plugins
Chart.register(ChartDataLabels);

interface BarChartProps {
    datasets: {
        label: string;
        data: number[];
        borderColor: string;
    }[];
    labels: string[];

}

const BarChart: React.FC<BarChartProps> = ({ datasets, labels, }) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart>();

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d');
            if (ctx) {
                chartInstance.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: datasets.map(dataset => ({
                            label: dataset.label,
                            data: dataset.data,
                            borderColor: dataset.borderColor,
                            backgroundColor: dataset.borderColor,
                            borderWidth: 2,
                            fill: false
                            
                        }))
                        
                    },
                    options: {
                        responsive: true,

                        plugins: {
                            title: {
                                display: true,
                                text: ''
                            },
                            legend: {
                                position: 'bottom' as const,

                            },
                            datalabels: {
                                color: 'black',
                                anchor: 'end' as const,
                                align: 'end' as const,
                                //clip: true,
                                font: {
                                    weight: 'normal' as const,
                                    size: 12,
                                },
                                padding: {
                                    top: 5,
                                },
                                formatter: (value: number) => value.toLocaleString(),
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Days'
                                },
                               
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Storage Spare capacity(bbls)'
                                }
                            }
                        }
                    }
                });
            }
        }

        // Clean up on component unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [datasets, labels]);

    return <canvas ref={chartContainer} />;
};

export default BarChart;