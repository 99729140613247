
import { memo, useState } from 'react';
import { emptyScenario, FormEditMode } from "../../../helpers/constants";
import OilReinjectionProfileDefinition from '../OilReinjectionProfile'
import { Scenario } from "../../../helpers/interfaces";
import { useScenarioContext } from "../../../views/ViewScenarioRun";
import OverviewCustomDialog from "../../CustomDialogueOverview";


interface Props {
    updateScenario:(scenario: Scenario) => void
    openOilReInjection: boolean
    setOpenOilReInjection: React.Dispatch<React.SetStateAction<boolean>>
}

function OilReinjectionKPI(props:Props) {
    const [privateScenario, setPrivateScenario] = useState<Scenario>(emptyScenario)
    const {  setScenario } = useScenarioContext();
    function update(){
        props.updateScenario(privateScenario)
        props.setOpenOilReInjection(false)
        setPrivateScenario(emptyScenario)
        setScenario(emptyScenario)
      }
  return (
    <>
    <OverviewCustomDialog dialogTitle={ 'Update Oil Reinjection Event '} setOpenDialog={props.setOpenOilReInjection} openDialog={props.openOilReInjection} buttonTitle="Update Oil Reinjection Event" buttonFunction={update}>
        <form>
        < OilReinjectionProfileDefinition formEditMode={FormEditMode.EDIT} privateScenario={privateScenario} setPrivateScenario={setPrivateScenario} />
          
        </form >
    </OverviewCustomDialog>

</>
  )
}

export default memo(OilReinjectionKPI)