import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfig } from '../helpers/interfaces';

interface AppConfigState {
    value: AppConfig
}

const defaultAppConfig: AppConfig = {
    SERVER_URL: process.env.REACT_APP_SERVER_URL ?? "",
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? "",
    APP_VERSION: process.env.REACT_APP_VERSION ?? "",
    APP_LAST_UPDATED: Number(process.env.REACT_APP_LAST_UPDATED ?? 0) * 1000,
    APP_PLATFORM: process.env.REACT_APP_PLATFORM ?? "",
    DARK_MODE: false
}

export const appConfig = createSlice({
    name: 'appConfig',
    initialState: {
        value: defaultAppConfig,
    } as AppConfigState,
    reducers: {
        setAppConfig: (state, action: PayloadAction<AppConfig>) => {
            state.value = action.payload
        },
    },
})

export const { setAppConfig } = appConfig.actions;
export default appConfig.reducer;