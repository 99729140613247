import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../store/store";
import LoginTepngUser from "../views/LoginTepngUser";
import { RoutePathNames } from "../helpers/constants";
import React from "react";
import MainLayout from "../views/MainLayout";
import UserList from "../views/UserList";
import TanksList from "../views/TankList";
import ViewCompressorSettings from "../views/ViewCompressorSettings";
import WellList from "../views/WellList";
import ScenarioRunList from "../views/ScenarioRunList";
import ViewScenarioRun from "../views/ViewScenarioRun";
import ExecutiveSummary from "../views/ExecutiveSummary";





interface ProtectedRouteProps {
  children: React.ReactNode
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
  const location = useLocation();

  if (loggedOnUserProfile == null) {
    localStorage.setItem("loginRedirectedFromUrl", location.pathname + location.search);
    return <><Navigate to={"/" + RoutePathNames.LOGIN} replace /></>
  }
  else
    return <>{children}</>
}

function NoRouteMatch() {
  const location = useLocation();
  return (<div> <h3> No Route match for <code>{location.pathname}</code> </h3> </div>);
}


export function PropTSCRoutes() {
  return (
    <Routes>
      <Route path={"/"} element={<LoginTepngUser />} />
      <Route path={"/" + RoutePathNames.LOGIN} element={<LoginTepngUser />} />

      <Route element={<MainLayout />}>
       <Route path = {"/" + RoutePathNames.USERS} element={<ProtectedRoute><UserList /></ProtectedRoute>} />
       <Route path = {"/" + RoutePathNames.COMPRESSOR_SETTINGS} element={<ProtectedRoute> <ViewCompressorSettings /> </ProtectedRoute>} />
      <Route path = {"/" + RoutePathNames.TANKS} element={<ProtectedRoute> <TanksList /> </ProtectedRoute>} />
      <Route path = {"/" + RoutePathNames.WELLS} element={<ProtectedRoute> <WellList /> </ProtectedRoute>} />
      <Route path = {"/" + RoutePathNames.SCENARIO_RUN_HISTORY} element={<ProtectedRoute> <ScenarioRunList /> </ProtectedRoute>} />
      <Route path = {"/" + RoutePathNames.SCENARIO_RUN + '/:id'} element={<ProtectedRoute> <ViewScenarioRun /> </ProtectedRoute>} />

      </Route>
      <Route path = {"/" + RoutePathNames.EXECUTIVE_SUMMARY + '/:id'} element={ <ExecutiveSummary /> } />
      <Route path="*" element={<NoRouteMatch />} />
    </Routes>
  );
}