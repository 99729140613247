import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TepngUser } from '../helpers/interfaces';

interface UserProfileState {
    value: TepngUser | null
}

export const loggedOnUserProfile = createSlice({
    name: 'loggedOnUserProfile',
    initialState: {
        value: null,
    } as UserProfileState,
    reducers: {
        setLoggedOnUserProfile: (state, action: PayloadAction<TepngUser | null>) => {
            state.value = action.payload
        },
    },
})

export const { setLoggedOnUserProfile } = loggedOnUserProfile.actions;
export default loggedOnUserProfile.reducer;