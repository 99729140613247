
import OilWellStatus from "../OilWellStatus";
import { memo } from 'react';
import { useScenarioContext } from "../../../views/ViewScenarioRun";
import { emptyScenario, FormEditMode } from "../../../helpers/constants";
import { Scenario, WellStatuses, } from "../../../helpers/interfaces";
import OverviewCustomDialog from "../../CustomDialogueOverview";

interface Props {
    updateScenario: (scenario: Scenario) => void
    openOilWells: boolean
    setOpenOilWells: React.Dispatch<React.SetStateAction<boolean>>
}
function OilWellsKPI(props: Props) {
    const {  scenario, setScenario, } = useScenarioContext();
    function update() {
        props.updateScenario(scenario)
        props.setOpenOilWells(false)
        setScenario(emptyScenario)
    }
    function handleWellStatusChange(wellstatuses:WellStatuses){
        setScenario({ ...scenario, well_statuses: wellstatuses })
    }
    return (
        <>
            <OverviewCustomDialog dialogTitle={'Update Oil Wells '} setOpenDialog={props.setOpenOilWells} openDialog={props.openOilWells} buttonTitle="Update Oil Wells" buttonFunction={update} >
                <form>
                    <OilWellStatus formEditMode={FormEditMode.EDIT}  handleWellStatusChange={handleWellStatusChange}  />
                    
                </form >
            </OverviewCustomDialog>

        </>
    )
}

export default memo(OilWellsKPI)
