
import { memo } from 'react';
import { useScenarioContext } from "../../../views/ViewScenarioRun";
import { emptyScenario, FormEditMode } from "../../../helpers/constants";
import GasWellStatus from "../GasWellStatus";
import { Scenario, WellStatuses } from "../../../helpers/interfaces";
import OverviewCustomDialog from "../../CustomDialogueOverview";

interface Props {
    updateScenario:(scenario: Scenario) => void
    openGasWells: boolean
    setOpenGasWells: React.Dispatch<React.SetStateAction<boolean>>
}
function GasWellsKPI(props: Props) {
    const {  scenario, setScenario,   } = useScenarioContext();
    function update(){
         props.updateScenario(scenario)
        props.setOpenGasWells(false)
        setScenario(emptyScenario)
      }
      function handleWellStatusChange(wellstatuses:WellStatuses){
        setScenario({ ...scenario, well_statuses: wellstatuses })
    }
    return (
        <>
            <OverviewCustomDialog dialogTitle={'Update Gas Wells '} setOpenDialog={props.setOpenGasWells} openDialog={props.openGasWells} buttonTitle="Update Gas Wells" buttonFunction={update}>
                <form>
                    <GasWellStatus formEditMode={FormEditMode.EDIT} handleWellStatusChange={handleWellStatusChange}  />
                   
                </form >
            </OverviewCustomDialog>

        </>
    )
}

export default memo(GasWellsKPI)
