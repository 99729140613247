import { Add, CopyAll, Delete, Download, Edit, Insights, MoreVert, Refresh, RemoveRedEyeOutlined, Summarize, ThumbUp, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Divider, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, useTheme, } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { Scenario, ScenarioRun, ScenarioRunSummary, Tank } from '../../helpers/interfaces'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { FormEditMode, RoutePathNames, ScenarioRunDisplay, Status, WellType } from '../../helpers/constants'
import { deleteScenario, duplicateScenario, exportScenarioRun, flagRecommendedScenario, refreshScenarioRun } from '../../apiServices/ScenarioApi'
import EditScenarioName from './EditScenarioName'
import { formatShortDateString, showConfirmBox } from '../../helpers/utils'
import { StyledTableCell, StyledTableRow } from '../../layouts/theme';
import { useScenarioContext } from '../../views/ViewScenarioRun'
import { saveAs } from 'file-saver'
import { store } from '../../store/store'
import CreateEditScenarioRun from './CreateEditScenarioRun'
import { getScenarioRunHistory } from '../../apiServices/ScenarioRunApi'


interface Props {
    excludedTanks: string
    includedTanks: Tank[]
    setScenarioId: React.Dispatch<React.SetStateAction<string>>
    scenarioRunDisplay: ScenarioRunDisplay
    setScenarioRunDisplay: (scenarioRunDisplay: ScenarioRunDisplay) => void
}

function ViewScenariosSummaryTable(props: Props) {

    const theme = useTheme();
    const appConfig = store.getState().appConfig.value;
    const { scenarioId, scenario, setScenario, scenarioRun, setScenarioRun } = useScenarioContext();
    const [openEditScenarioNameForm, setOpenEditScenarioNameForm] = useState<boolean>(false)
    const [tanksIncluded, setTanksIncluded] = useState<string>('')
    const [openScenarioRunForm, setOpenScenarioRunForm] = React.useState(false);
    const [, setScenarioRunSummary] = useState<ScenarioRunSummary[]>([])

    const [scenarioRunEditMode, setScenarioRunEditMode] = React.useState(FormEditMode.CREATE);
    var hiddenScenarios = Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => scenario.hidden).map(([id, scenario], i) => id).join(",")

   useEffect(()=> {

    const included_Tanks = props.includedTanks.map((e, i) => e.name)
    setTanksIncluded(included_Tanks.join(", "))
   }, [props.includedTanks])

    function exportScenario() {
        exportScenarioRun(scenarioRun.id).then(async (base64string) => {
            const blob = base64ToBlob(base64string, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            saveAs(blob, `ScenarioRun - ${scenarioRun.description}.xlsx`);
        })
    }

    function base64ToBlob(base64string: string, type: string) {
        const binaryString = window.atob(base64string);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type: type });
    }

    function handleRefreshScenarioRun() {
        refreshScenarioRun(scenarioRun.id).then(_scenarioRun => {
            setScenarioRun(_scenarioRun)
        })
    }

    function handleScenarioRecommendation(scenario_id: string) {
        let _scenarioToRecommend = scenarioRun.scenarios[scenario_id]
        flagRecommendedScenario(scenarioRun.id, scenario_id).then(_scenarioRun => {

            _scenarioToRecommend.recommended_scenario_id = scenario_id

            setScenarioRun({ ...scenarioRun, recommended_scenario_id: scenario_id })

        })
        console.log(_scenarioToRecommend.recommended_scenario_id)
        console.log(scenario_id)
    }
    function handleDeleteScenario(scenario_id: string) {
        showConfirmBox(`Are you sure you want to delete Scenario ${scenarioRun.scenarios[scenario_id].name} ?`, "Delete Scenario")
            .then((result) => {
                if (result.isConfirmed)
                    deleteScenario(scenarioRun.id, scenario_id).then(_ => {
                        let scenarios = scenarioRun.scenarios
                        delete scenarios[scenario_id]
                        setScenarioRun({ ...scenarioRun, scenarios: scenarios })
                    })
            })
    }

    const toggleScenarioHidden = (scenarioId: string) => {
        let _scenarioToEdit = scenarioRun.scenarios[scenarioId]
        _scenarioToEdit.hidden = !_scenarioToEdit.hidden
        setScenarioRun({ ...scenarioRun, scenarios: { ...scenarioRun.scenarios, [scenarioId]: _scenarioToEdit } })
        console.log(scenarioRun.scenarios[scenarioId].hidden)
        props.setScenarioId('')
    };
    const handleDuplicateScenario = (scenarioId: string) => {
        duplicateScenario(scenarioRun.id, scenarioId).then(__scenarioRun => {
            setScenarioRun(__scenarioRun)
        })
        props.setScenarioId('')
    };
    const handleClosePopupState = (popupState: { close: () => void }) => {
        popupState.close();
    };
    const handleAddScenario = () => {
        props.setScenarioId('')
        props.setScenarioRunDisplay(ScenarioRunDisplay.ADD_SCENARIO)
        console.log(scenarioId, 'em')
    };
    function closedOilWells(id: string, scenarioRun: ScenarioRun, e: Scenario) {
        let closed_wells = Object.entries(e.well_statuses).filter(([name, status]) => status === Status.CLOSED && !scenarioRun.wells.filter(e => (e.status === Status.CLOSED)).map(e => e.name).includes(name))
        let closed_oil_wells = closed_wells.filter(([name, status]) => scenarioRun.wells.find(e => e.name === name)?.type === WellType.OIL)
        let list_of_closed_oil_wells = closed_oil_wells.map(([name, status]) => name).join(", ")


        return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }}>
            {closed_oil_wells.length > 0 ?
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                        <span>{list_of_closed_oil_wells.substring(0, list_of_closed_oil_wells.length)}</span>
                    </div>
                </div> : 'No Oil Well Closed'}

        </StyledTableCell>

    }
    function closedGasWells(id: string, scenarioRun: ScenarioRun, e: Scenario) {
        let closed_wells = Object.entries(e.well_statuses).filter(([name, status]) => status === Status.CLOSED && !scenarioRun.wells.filter(e => (e.status === Status.CLOSED)).map(e => e.name).includes(name))

        let closed_gas_wells = closed_wells.filter(([name, status]) => scenarioRun.wells.find(e => e.name === name)?.type === WellType.GAS)

        let list_of_closed_gas_wells = closed_gas_wells.map(([name, status]) => name).join(", ")

        return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }}>
            {closed_gas_wells.length > 0 ?
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                        <span>{list_of_closed_gas_wells.substring(0, list_of_closed_gas_wells.length)}</span>
                    </div>
                </div> : 'No Gas Well Closed'}
        </StyledTableCell>

    }

    function openScenarioRunPrametersForm(){
        setOpenScenarioRunForm(true);
        setScenarioRunEditMode(FormEditMode.EDIT)
    }
    return (
        <>
            <Box>
                <>
                    <Box mt="10px" p='1.0rem' gap="20px">

                        <Paper sx={{ padding: '1.0rem' }}>
                            <div>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" style={{ border: `1px solid ${theme.palette.action.hover}` }}>

                                    <TableHead >
                                        <StyledTableRow> <StyledTableCell colSpan={7} style={{ color: theme.palette.text.primary }}> Scenario Run Parameters  {scenarioRun.test_run ? '- Test Run':' '}</StyledTableCell>
                                            <StyledTableCell  >
                                                <Button variant='contained' color='info' onClick={() => {openScenarioRunPrametersForm()}}> Edit Parameters </Button></StyledTableCell></StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell > Implementation Start</StyledTableCell>
                                            <StyledTableCell> Last Refresh Date</StyledTableCell>
                                            <StyledTableCell > Included Tanks</StyledTableCell>
                                            <StyledTableCell > Min. LP Compressors to run</StyledTableCell>
                                            <StyledTableCell > Min. MP Compressors to run</StyledTableCell>
                                            <StyledTableCell  > Min. HP Compressors to run</StyledTableCell>
                                            <StyledTableCell> Gas for Internal Use/Flaring</StyledTableCell>
                                            <StyledTableCell >Max. Condensate Oil Ratio </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow >
                                            <StyledTableCell >{formatShortDateString(scenarioRun.implementation_start_date, true)}</StyledTableCell>
                                            <StyledTableCell  >{formatShortDateString(scenarioRun.last_refresh_date, true)}</StyledTableCell>
                                            <StyledTableCell style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }} >

                                                <div>
                                                    <span>{tanksIncluded.substring(0, tanksIncluded.length)}</span>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell >{scenarioRun.min_no_lp_compressors_to_run} LP ({scenarioRun.min_no_lp_compressors_to_run === 2 ? scenarioRun.compressor_settings.min_daily_gas_production_to_run_2_lp : scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_lp * scenarioRun.min_no_lp_compressors_to_run} Ksm3/d)</StyledTableCell>
                                            <StyledTableCell >{scenarioRun.min_no_mp_compressors_to_run} MP ({scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_mp * scenarioRun.min_no_mp_compressors_to_run} Ksm3/d)</StyledTableCell>
                                            <StyledTableCell >{scenarioRun.min_no_hp_compressors_to_run} HP ({scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_hp * scenarioRun.min_no_hp_compressors_to_run} Ksm3/d)</StyledTableCell>
                                            <StyledTableCell >{scenarioRun.gas_for_internal_use_and_flare}</StyledTableCell>
                                            <StyledTableCell >{scenarioRun.maximum_condensate_oil_ratio}%</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                        <br />
                        <div>
                            <div style={{ float: 'right' }} >
                                <Button variant='outlined' color='info' onClick={(e) => {!scenarioRun.recommended_scenario_id ? alert('You have not recommended a Scenario') :window.open(`/${RoutePathNames.EXECUTIVE_SUMMARY}/${scenarioRun.id}?scenarioId=${hiddenScenarios}`) }}  ><Summarize /> &nbsp; View Executive Summary</Button>&nbsp;&nbsp;
                                <Button color='info' variant="contained" onClick={handleRefreshScenarioRun} ><Refresh /> Refresh</Button>&nbsp;&nbsp;
                                <Button color='secondary' variant="contained" onClick={handleAddScenario} ><Add /> Add New Scenario</Button>&nbsp;&nbsp;
                                <Button color='info' variant="contained" onClick={exportScenario}  > <Download />Export To Excel</Button>
                            </div>
                            <br />
                            <br />

                            <TableContainer className="shadow-md sm:rounded-lg">
                                <Table className='summary overflow-x-auto table-auto' size="small" aria-label="a dense table"
                                    aria-labelledby="tableTitle"
                                >
                                    <TableHead >
                                        <StyledTableRow>
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px' }}><div>
                                                {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => scenario.hidden).length > 0 &&
                                                    <div>
                                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                                            {(popupState) => (
                                                                <React.Fragment>
                                                                    <Button variant="text" color='info' {...bindTrigger(popupState)}>
                                                                        <RemoveRedEyeOutlined /> &nbsp; Restore Hidden Scenario
                                                                    </Button>
                                                                    <Menu {...bindMenu(popupState)}>
                                                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => scenario.hidden).map(([id, scenario], i) => {
                                                                            return (
                                                                                <MenuItem key={i} onClick={() => { toggleScenarioHidden(id); popupState.close() }} >{scenario.name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Menu>
                                                                </React.Fragment>
                                                            )}
                                                        </PopupState>
                                                    </div>
                                                }
                                            </div></StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return (
                                                    <>
                                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} key={'btnHdn' + id} style={{ minWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderTop: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderBottom: ' 1px solid #cdcdcd' }}>

                                                            <div className='flex '>
                                                                <p className='flex w-[90%]' style={{ marginTop: '8px' }} title={e.name}>{e.name}
                                                                </p>
                                                                <p className='felx w-[10%]'>
                                                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                                                        {(popupState) => (
                                                                            <React.Fragment>
                                                                                <IconButton color='inherit' {...bindTrigger(popupState)}><MoreVert /></IconButton>
                                                                                <Menu {...bindMenu(popupState)}>
                                                                                    <MenuItem onClick={() => { props.setScenarioId(id); setScenario(scenario); props.setScenarioRunDisplay(ScenarioRunDisplay.SCENARIO_DETAILS) }}> <Insights color='secondary' /> &nbsp;&nbsp; Overview</MenuItem>
                                                                                    <Divider />
                                                                                    <MenuItem onClick={() => { props.setScenarioId(id); setScenario(scenario); setOpenEditScenarioNameForm(true); handleClosePopupState(popupState) }} disabled={e.do_nothing === true} ><Edit color='info' />&nbsp;&nbsp;Update Name & Description</MenuItem>
                                                                                    <Divider />
                                                                                    <MenuItem key={'btnHdn' + id} onClick={() => { toggleScenarioHidden(id); handleClosePopupState(popupState) }} disabled={e.do_nothing === true} ><VisibilityOff sx={{ color: 'gray' }} />&nbsp;&nbsp; Hide Column</MenuItem>
                                                                                    <Divider />
                                                                                    <MenuItem onClick={() => { handleDuplicateScenario(id); handleClosePopupState(popupState) }} ><CopyAll sx={{ color: '#eda674' }} /> &nbsp;&nbsp; Duplicate </MenuItem>
                                                                                    <Divider />
                                                                                    <MenuItem onClick={() => { handleScenarioRecommendation(id); handleClosePopupState(popupState) }} ><ThumbUp sx={{ color: `${theme.palette.success.main}` }} /> &nbsp;&nbsp; Recommend </MenuItem>
                                                                                    <Divider />
                                                                                    <MenuItem key={'btnDelete' + id} onClick={() => { handleDeleteScenario(id); handleClosePopupState(popupState) }} disabled={e.do_nothing === true}><Delete color='secondary' /> &nbsp;&nbsp; Delete </MenuItem>

                                                                                </Menu>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </PopupState>
                                                                </p>
                                                            </div>
                                                        </StyledTableCell>
                                                    </>
                                                )
                                            })}

                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Description</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }}>{e.description}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Closed Oil Wells </StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return closedOilWells(id, scenarioRun, e)
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Closed Gas Wells </StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return closedGasWells(id, scenarioRun, e)
                                            })}
                                        </StyledTableRow>

                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >Gas for Export (MMSm3/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{((e.produced_gas / 1000).toFixed(3))}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >Condensate Produced (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.produced_condensate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>Condensate Oil ratio </StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'medium', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} title={'Max COR  = ' + (scenarioRun.maximum_condensate_oil_ratio ) + '%'}>
                                                    <span style={{ color: e.condensate_oil_ratio <= scenarioRun.maximum_condensate_oil_ratio ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>
                                                        {(e.condensate_oil_ratio * 100)?.toFixed(2)}%
                                                    </span>
                                                </StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }}>Oil Produced  (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.produced_oil?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}

                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }}>Spike into OUR(bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.condensate_spike?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' ,}}>Oil reinjected(bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.oil_reinjected?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>

                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px',  fontWeight: 'bold'}}>Condensate filling rate send to OFS (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{parseFloat(e.condensate_filling_rate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px',  fontWeight: 'bold'}} >Total liquid Filling rate at OFS (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{parseFloat(e.total_liquid_filling_rate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow   >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Total Liquid produced (Excluding reinjection) (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{parseFloat(e.total_liquid_produced?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px',  fontWeight: 'bold'}}>Water export / Injection (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{parseFloat(e.water_export_and_injection?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow   >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>Gas for the MP Comp.(MMSm3/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{((e.gas_for_mp_compressor / 1000).toFixed(3))}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Gas for LP Compressor (MMSm3/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{((e.gas_for_lp_compressor / 1000)?.toFixed(3))}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }} >Gas for HP Compressor (MMSm3/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{((e.gas_for_hp_compressor / 1000).toFixed(3))}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow   >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>Storage Space Lost per Day (bbls/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'medium', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >
                                                    <span style={{ color: e.storage_space_gain_or_lost > 0 ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>
                                                        {parseFloat(e.storage_space_gain_or_lost?.toFixed(1))?.toLocaleString('en')}
                                                    </span>
                                                </StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow   >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>HP compressor running</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} ><span style={{ color: scenarioRun.min_no_hp_compressors_to_run <= e.hp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.hp_compressors_running}HP</span></StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow   >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>MP+LP compressors running(MMsm3/d)</StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >
                                                    <span style={{ fontWeight: 'medium', color: scenarioRun.min_no_lp_compressors_to_run <= e.lp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.lp_compressors_running}LP</span>
                                                    &nbsp;&nbsp;+ &nbsp;&nbsp;
                                                    <span style={{ fontWeight: 'medium', color: scenarioRun.min_no_mp_compressors_to_run <= e.mp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.mp_compressors_running}MP</span>
                                                </StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow  >

                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>Day to reach top tank <br />
                                                {props.excludedTanks.length > 0 &&
                                                    <>
                                                        <span style={{ color: theme.palette.secondary.main }}>Without {props.excludedTanks.substring(0, props.excludedTanks.length)}</span>

                                                    </>
                                                }
                                            </StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ background: 'yellow', fontWeight: 'bold', maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{formatShortDateString(e.day_to_reach_top_tank, true)} </StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell className={'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold' }}>Autonomy (days) <br />
                                                {props.excludedTanks.length > 0 &&
                                                    <>
                                                        <span style={{ color: theme.palette.secondary.main }}>Without {props.excludedTanks.substring(0, props.excludedTanks.length)}</span>

                                                    </>
                                                }
                                            </StyledTableCell>
                                            {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                                return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', maxWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderBottom: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '' }} >{Math.floor(e.autonomy_hours / 24)} days {e.autonomy_hours % 24 > 0 ? e.autonomy_hours % 24 + ' hours' : ''} </StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <br />
                    </Box>
                </>
            </Box>


            {openEditScenarioNameForm && scenarioId &&
                <EditScenarioName setScenarioId={props.setScenarioId} scenarioIdToEdit={scenarioId} scenarioRun={scenarioRun} setScenarioRun={setScenarioRun} openEditScenarioNameForm={openEditScenarioNameForm} setOpenEditScenarioNameForm={setOpenEditScenarioNameForm} />
            }
               {openScenarioRunForm &&
                            <CreateEditScenarioRun updateScenarioRun={setScenarioRun} setFormEditMode={setScenarioRunEditMode} formEditMode={scenarioRunEditMode} scenarioRunToEdit={scenarioRun}  getScenarioRunHistory={getScenarioRunHistory} setScenarioRunSummary={setScenarioRunSummary}  openScenarioRunForm={openScenarioRunForm} setOpenScenarioRunForm={setOpenScenarioRunForm} />
                        }
        </>
    )
}

export default ViewScenariosSummaryTable