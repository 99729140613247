import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, Well } from "../helpers/interfaces";
import { showBackDrop, callServerAPI } from '../helpers/utils'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);


export const uploadWellsExcelFile = async (formData: FormData) => {
    return await new Promise<Well[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Uploading WIS Data"));
        callServerAPI<ServerAPIResponse<Well[]>>('POST', `/api/well/upload-wells-excel`, formData, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Upload Successful", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export const getAllWells = async () => {
    return await new Promise<Well[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Well Data"));
        callServerAPI<ServerAPIResponse<Well[]>>('GET', '/api/well/get-wells', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function updateWellStatusHeader(id: string, status: string, header: string) {
    MySwal.fire(showBackDrop("Updating Well Data"));
    callServerAPI<ServerAPIResponse<Well[]>>('PATCH', `/api/well/update-well-status-header?id=${id}&status=${status}&header=${header}`, null, true)
        .then(response => {
            MySwal.close()
            enqueueSnackbar(" Well Data Updated", { variant: 'success' });

        })
}