import { Button, Table, TableBody, TableHead, } from '@mui/material'
import { formatDateTimeLocal } from '../../helpers/utils';
import { emptyOilReinjectionProfile, FormEditMode } from '../../helpers/constants';
import { Delete } from '@mui/icons-material';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../../layouts/theme';
import { useEffect, memo,  } from 'react';
import { useScenarioContext } from '../../views/ViewScenarioRun';
import { Scenario } from '../../helpers/interfaces';
import { NumericFormat } from 'react-number-format';
import React from 'react';


interface Props {
    formEditMode: number
    privateScenario: Scenario
    setPrivateScenario: (scenario:Scenario) => void
}

function OilReinjectionProfileDefinition(props: Props) {
    const { scenarioId, scenario, setScenario, scenarioRun,  } = useScenarioContext();
     useEffect(() => {
         if (props.formEditMode === FormEditMode.EDIT)
           props.setPrivateScenario(scenarioRun.scenarios[scenarioId])
        // eslint-disable-next-line
    }, [])

   
 
    const minDate = new Date(scenarioRun.implementation_start_date).toISOString().slice(0, 16); // Sets the minimum date to the current date and time

    const addNewOilReinjection = () => {
        var next_available_date_hour: Date | null = null
        if ( props.privateScenario.oil_reinjection_profiles.length === 0) {
            next_available_date_hour = scenarioRun.implementation_start_date
            //next_available_date_hour.setMinutes(0, 0, 0)
        }
        else {
            const lastest_profile = props.privateScenario.oil_reinjection_profiles[props.privateScenario.oil_reinjection_profiles.length - 1]
            next_available_date_hour = new Date(lastest_profile.start_date_time)
            next_available_date_hour.setTime(next_available_date_hour.getTime() + lastest_profile.duration_hours * 60 * 60 * 1000)
        }
        props.setPrivateScenario({ ...props.privateScenario, oil_reinjection_profiles: [...props.privateScenario.oil_reinjection_profiles, { ...emptyOilReinjectionProfile, start_date_time: next_available_date_hour }] })
        if (props.formEditMode !== FormEditMode.EDIT)
            setScenario({ ...scenario, oil_reinjection_profiles: [...scenario.oil_reinjection_profiles, { ...emptyOilReinjectionProfile, start_date_time: next_available_date_hour }] })
    }

    const updateOilReinjection = (start_date_time: Date, name: string, value: any) => {
        if (name === 'start_date_time')
            value.setMinutes(0, 0, 0)
        else if (name === 'duration_hours') {
            if (value < 1) return
            value = parseInt(value)
        }
        else if (name === 'injection_rate_bbls_per_day') {
            if (value <= 0) return
        }
        
        if (props.formEditMode !== FormEditMode.EDIT){
            setScenario({ ...scenario, oil_reinjection_profiles: scenario.oil_reinjection_profiles.map(inst => inst.start_date_time !== start_date_time ? inst : { ...inst, [name]: value }) })
        }else{
            props.setPrivateScenario({ ...props.privateScenario, oil_reinjection_profiles: props.privateScenario.oil_reinjection_profiles.map(inst => inst.start_date_time !== start_date_time ? inst : { ...inst, [name]: value }) })
        }

    }

    const deleteOilReinjection = (start_date_time: Date) => {
       
        if (props.formEditMode !== FormEditMode.EDIT){
            setScenario({ ...scenario, oil_reinjection_profiles: scenario.oil_reinjection_profiles.filter(inst => inst.start_date_time !== start_date_time) })
        }else{
            props.setPrivateScenario({ ...props.privateScenario, oil_reinjection_profiles: props.privateScenario.oil_reinjection_profiles.filter(inst => inst.start_date_time !== start_date_time) })
        }
        }
      

    return (
        scenario &&
        <div>
            <span style={{ float: 'right' }}>
                <Button onClick={addNewOilReinjection} variant='outlined' color='secondary' >Add Oil ReInjection Event</Button>
            </span>
            <br />
            <br />
            <div className="relative overflow-x-auto ">
                <Table>
                    <TableHead >
                        <StyledTableRow><StyledTableCell colSpan={4} scope="col" >Oil ReInjection Profiles</StyledTableCell></StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell > Start Date </StyledTableCell>
                            <StyledTableCell > Duration(hrs)</StyledTableCell>
                            <StyledTableCell> Amount(bbls/day)</StyledTableCell>
                            <StyledTableCell > Actions</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody >
                        {props.privateScenario.oil_reinjection_profiles.map((item, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>  
                                        <CustomTextField
                                        InputLabelProps={{ shrink: true, }} 
                                         inputProps={{ min : minDate }} 
                                          disabled={props.privateScenario.oil_reinjection_profiles.length !== 1} 
                                           size="small" 
                                           type='datetime-local' 
                                           onChange={(e) => updateOilReinjection(item.start_date_time, e.target.id, new Date(e.target.value))} 
                                           id="start_date_time" 
                                           value={formatDateTimeLocal(item.start_date_time)} />
                       
                                        </StyledTableCell>
                                    <StyledTableCell>
                                        <NumericFormat  size="small" customInput={CustomTextField}  disabled={index !== props.privateScenario.oil_reinjection_profiles.length - 1}   id='duration_hours' value={item.duration_hours} onChange={(e: { target: { id: string; value: any; }; }) => updateOilReinjection(item.start_date_time, e.target.id, e.target.value)} />
                                            
                                        </StyledTableCell>
                                    <StyledTableCell><NumericFormat   size="small" customInput={CustomTextField} id='injection_rate_bbls_per_day' value={item.injection_rate_bbls_per_day} onChange={(e: { target: { id: string; value: any; }; }) => updateOilReinjection(item.start_date_time, e.target.id, e.target.value)} /></StyledTableCell>
                                    <StyledTableCell>
                                        {index === props.privateScenario.oil_reinjection_profiles.length - 1 &&
                                            <Button variant='contained' color='secondary' size='small' onClick={() => { deleteOilReinjection(item.start_date_time) }}   > <Delete /></Button>
                                        }
                                    </StyledTableCell></StyledTableRow>
                            )
                        })}
                        {props.privateScenario.oil_reinjection_profiles.length === 0 ? <StyledTableRow>
                            <StyledTableCell colSpan={4}><p className=' text-center'> No record available...</p></StyledTableCell>


                        </StyledTableRow> : ''}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default  memo(OilReinjectionProfileDefinition)