import React, { useEffect, useState } from 'react'
import { createUserProfile, updateUserProfile } from '../../apiServices/UserManagementApi';
import CustomDialog from '../CustomDialogue';
import { Autocomplete, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getNameFromTotalEnergiesEmail } from '../../helpers/utils';
import { TepngUser } from '../../helpers/interfaces';
import { Add } from '@mui/icons-material';
import { FormEditMode } from '../../helpers/constants';
import { useAppSelector } from '../../store/store';
import { CustomTextField } from '../../layouts/theme';

interface Props {
    getAllUserProfiles: () => Promise<TepngUser[]>
    setFormEditMode: React.Dispatch<React.SetStateAction<number>>
    formEditMode: number
    setUsers: React.Dispatch<React.SetStateAction<TepngUser[]>>
    users: TepngUser[]
    userToEdit: string
    openUserForm: boolean
    setOpenUserForm: React.Dispatch<React.SetStateAction<boolean>>

}

function CreateUser(props: Props) {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [newUserEmail, setNewUserEmail] = useState<string>("");
    const cmdbUserEmails = useAppSelector(state => state.cmdbUsers.value).map(e=>e.email);


    useEffect(() => {
        if (props.formEditMode === FormEditMode.EDIT) {
            var userEmail = props.users.find(e => e.id === props.userToEdit)
            if (!userEmail) return
            setNewUserEmail(userEmail.email)
            setIsAdmin(userEmail.is_admin)
        }
    }, [props.formEditMode, props.users, props.userToEdit])


    function createNewUser() {
        createUserProfile(newUserEmail, isAdmin).then(user => {
            props.setOpenUserForm(false);
            props.setUsers(users => [...users, user])
        })
    }

    function updateUser() {
        updateUserProfile(newUserEmail, isAdmin).then(result => {
            props.setOpenUserForm(false);
            props.setUsers(users => users.map(u => u.email === newUserEmail ? result : u))
        })
    }

    return (
        cmdbUserEmails &&
        <>
            <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? 'Add New User ' : 'Update User '} setOpenDialog={props.setOpenUserForm} openDialog={props.openUserForm}>
                <form>
                    <div >
                        <label className='title-label'>Select User Email <span className='important'>*</span></label>
                    </div>
                    <div className='col-12'>
                        <div className="form-group mb-0">
                            <div className="">
                                <Autocomplete id="newUserEmail" options={cmdbUserEmails} autoSelect multiple={false} size="small"
                                    value={newUserEmail} onChange={(event, newValue) => setNewUserEmail(newValue!)}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    renderInput={(params) => (<CustomTextField {...params} margin="none" variant="outlined" size='small' />)}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div >
                        <label className='title-label'>Role <span className='important'>*</span></label>
                    </div>
                    <div className='col-12'>
                        <div className="form-group mb-0">
                            <div className="">
                                <RadioGroup>
                                    <FormControlLabel value={true} control={<Radio color='secondary' checked={isAdmin} onClick={() => setIsAdmin(true)} />} label='Admin' />
                                    <FormControlLabel value={false} control={<Radio color='secondary' checked={!isAdmin} onClick={() => setIsAdmin(false)} />} label='User' />
                                </RadioGroup>

                            </div>
                        </div>
                    </div>
                    <br />

                    <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                        <Button disabled={!newUserEmail} variant="contained" color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? createNewUser : updateUser} >
                            <Add />&nbsp; {props.formEditMode === FormEditMode.CREATE ? 'Add User' : 'Update User'}
                        </Button>
                    </div>
                </form >


            </CustomDialog>

        </>
    )
}

export default CreateUser