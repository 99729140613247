import React, { useEffect, useState } from 'react'
import { Dictionary, Well, WellStatuses } from '../../helpers/interfaces';
import { Grid, MenuItem, Pagination, Stack, Table, TableBody, TableHead, useTheme, } from '@mui/material'
import { FormEditMode, Status, WellType } from '../../helpers/constants';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../../layouts/theme';
import { useScenarioContext } from '../../views/ViewScenarioRun';
import { useAppSelector } from '../../store/store';
import { WaterDrop } from '@mui/icons-material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface Props {
    handleWellStatusChange: (c: WellStatuses) => void
    formEditMode: number
}

const statusOptions = [{ value: Status.ALWAYS_OPEN, label: 'Always Open' }, { value: Status.CLOSED, label: 'Closed' }, { value: Status.OPEN, label: 'Open' }]

function GasWellStatus(props: Props) {
    const wells = useAppSelector(state => state.wells.value)
    const [wellstatuses, setWellstatuses] = useState<Dictionary<string, string>>({});
    const theme = useTheme();
    // const [wellStatus, setWellStatus] = useState<{}>(emptyWellStatuses);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(60);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<Well[]>([])
    const [filteredWells, setFilteredWells] = useState<Well[]>([])
    const [statusFilter, setStatusFilter] = useState('All');
    const [well, setWell] = useState<Well[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { scenarioId, setScenario, scenarioRun, } = useScenarioContext();
    const [isHovered, setIsHovered] = useState(false);
    const [sortConfig, setSortConfig] = useState<{ column: keyof Well | null; direction: 'ascending' | 'descending' | null }>({
        column: null,
        direction: null,
    });

    useEffect(() => {
        setWell(scenarioRun.wells.filter(e => e.type === WellType.GAS))
        if (props.formEditMode === FormEditMode.EDIT) {
            setScenario(scenarioRun.scenarios[scenarioId])
            let status: Dictionary<string, string> = {}
            status = scenarioRun.scenarios[scenarioId].well_statuses
            setWellstatuses(status)
            let wells = scenarioRun.wells.map((e) => {
                e.status = scenarioRun.scenarios[scenarioId].well_statuses[e.name]
                return e
            })
            setFilteredWells(wells.filter(e =>
                (statusFilter === "All" || e.status.toLowerCase() === statusFilter.toLowerCase()) &&
                (searchQuery === "" || e.name.toLowerCase().includes(searchQuery.toLowerCase())) && e.type === WellType.GAS
            ));
        } else {
            let status: Dictionary<string, string> = {}
            scenarioRun.wells.filter(e => e.type === WellType.GAS).forEach((e) => {
                status[e.name] = e.status
            })
            setWellstatuses(status)
            setFilteredWells(scenarioRun.wells.filter(e =>
                (statusFilter === "All" || e.status.toLowerCase() === statusFilter.toLowerCase()) &&
                (searchQuery === "" || e.name.toLowerCase().includes(searchQuery.toLowerCase())) && e.type === WellType.GAS
            ));
        }

        setCurrentPage(1);
        // eslint-disable-next-line
    }, [searchQuery, statusFilter, props.formEditMode, scenarioId, scenarioRun.scenarios, scenarioRun.wells])

    useEffect(() => {
        setFilteredWells(well.filter(e =>
            (statusFilter === "All" || e.status.toLowerCase() === statusFilter.toLowerCase()) &&
            (searchQuery === "" || e.name.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, statusFilter, well])

    useEffect(() => {
        if (well !== null) {
            setNumOfPages(Math.ceil(filteredWells.length / recordsPerPage))
        }
    }, [recordsPerPage, filteredWells, well])

    useEffect(() => {
        if (well !== null) {
            const lastIndex = currentPage * recordsPerPage;
            const firstIndex = lastIndex - recordsPerPage;
            setCurrentPageRecords(filteredWells.slice(firstIndex, lastIndex))
        }
    }, [currentPage, recordsPerPage, filteredWells, well])


    const updateFieldValue = (name: any, value: string) => {
        let newWellStatus = { ...wellstatuses, [name]: value }
        props.handleWellStatusChange(newWellStatus)
        setWellstatuses(newWellStatus)
    }
    const handleSort = (column: keyof Well) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.column === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ column, direction });

        const sortedData = [...currentPageRecords].sort((a, b) => {
            if (a[column] < b[column]) return direction === 'ascending' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setCurrentPageRecords(sortedData);
    };
    return (
        <>
            <div className="relative overflow-x-auto ">
                <div className=" flex flex-row ">
                    <div className='column1' >
                        <div>Search</div>
                        <CustomTextField fullWidth size="small" variant="outlined" placeholder="Search by Well Name..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} /> &nbsp;

                    </div>
                    <div style={{ flexGrow: 1 }} />
                    <div className='column1' >
                        <span>Status Filter</span>
                        <CustomTextField fullWidth select variant="outlined" size="small" name="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                            <MenuItem value="All">All Status</MenuItem >
                            {statusOptions.map((status, index) => {
                                return <MenuItem key={index} value={status.value}>{status.label}</MenuItem >
                            })}

                        </CustomTextField>
                    </div>

                </div>
                <Table >
                    <TableHead >
                        <StyledTableRow>
                            <StyledTableCell colSpan={7} >
                                <div className='flex'>
                                    <span><img style={{ height: '16px' }} src='../../assets/images/gas.png' alt="logo.jpeg" /></span> &nbsp;&nbsp;<span>Gas Wells</span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell > Sn</StyledTableCell>
                            <StyledTableCell >Name</StyledTableCell>
                            <StyledTableCell >Status</StyledTableCell>
                            <StyledTableCell >  <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />Gas(KSCMD)</StyledTableCell>
                            <StyledTableCell >  <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />Cond (BCPD)</StyledTableCell>
                            <StyledTableCell >MCA (Y/N)</StyledTableCell>
                            <StyledTableCell onClick={() => handleSort('gcr')}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                GCR 
                                {isHovered && 
                                <>
                                  {sortConfig.column === 'gcr' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon  style={{fontSize:'13px'}}/> : <ArrowDownwardIcon  style={{fontSize:'13px'}} />) : <SwapVertIcon />}
                                  </> }
                            </StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageRecords.map((item, index) => {
                            return <StyledTableRow key={index}>

                                <StyledTableCell >{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                <StyledTableCell >{item.name}</StyledTableCell>

                                <StyledTableCell >
                                    <CustomTextField fullWidth size="small" disabled={wells.filter(e => e.name === item.name)[0]?.status !== Status.OPEN} select variant="outlined" id="status" value={wellstatuses[item.name]} onChange={(e) => updateFieldValue(item.name, e.target.value)}>
                                        {statusOptions.map((wellStatus, index) => {
                                            return <MenuItem key={index} value={wellStatus.value}>{wellStatus.label}</MenuItem >
                                        })}
                                    </CustomTextField>
                                </StyledTableCell>
                                <StyledTableCell >{item.gas}</StyledTableCell>
                                <StyledTableCell >{item.condensate}</StyledTableCell>
                                <StyledTableCell >{item.mca}</StyledTableCell>
                                <StyledTableCell >{item.gcr}</StyledTableCell>


                            </StyledTableRow>
                        })}

                        {currentPageRecords.length === 0 &&
                            <StyledTableRow>
                                <StyledTableCell colSpan={6}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
                <br />

                <nav>
                    <div className=" flex flex-row ">
                        <div className=" flex items-start ">
                            <div className="px-6 py-2">Rows per page</div>&nbsp;
                            < Grid item xs={12} lg={3} >
                                <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                    <MenuItem value="60">60</MenuItem >
                                    <MenuItem value="120">120</MenuItem >
                                    <MenuItem value="180">180</MenuItem >
                                    <MenuItem value="240">240</MenuItem >
                                    <MenuItem value="360">360</MenuItem >
                                    <MenuItem value="500">500</MenuItem >



                                </CustomTextField>
                            </Grid>
                        </div>
                        <div style={{ flexGrow: 1 }} />
                        <Stack spacing={2}>
                            <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                        </Stack>

                    </div>
                    <br />


                </nav>

            </div>
        </>
    )
}

export default GasWellStatus