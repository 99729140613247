import React, { useState } from 'react';
import CustomDialog from '../CustomDialogue';
import { Button } from '@mui/material';
import { ScenarioRun, ScenarionNameDescriptionDto } from '../../helpers/interfaces';
import { UpdateScenarioNameDescription } from '../../apiServices/ScenarioApi';
import { CustomTextField } from '../../layouts/theme';

interface Props {
    scenarioIdToEdit: string
    scenarioRun: ScenarioRun
    setScenarioRun: (c: ScenarioRun) => void
    openEditScenarioNameForm: boolean
    setOpenEditScenarioNameForm: React.Dispatch<React.SetStateAction<boolean>>
    setScenarioId: React.Dispatch<React.SetStateAction<string>>
}

function EditScenarioName(props: Props) {
    const [scenarioName, setScenarioName] = useState<string>(props.scenarioRun.scenarios[props.scenarioIdToEdit].name);
    const [scenarioDescription, setScenarioDescription] = useState<string>(props.scenarioRun.scenarios[props.scenarioIdToEdit].description);

    function updateName() {
        let updateDto:ScenarionNameDescriptionDto = {name:scenarioName, description:scenarioDescription }
        UpdateScenarioNameDescription(props.scenarioRun.id, props.scenarioIdToEdit, updateDto).then(_ => {
            let _scenarioToEdit = props.scenarioRun.scenarios[props.scenarioIdToEdit]
            _scenarioToEdit.name = scenarioName
            _scenarioToEdit.description = scenarioDescription
            props.setScenarioRun({ ...props.scenarioRun, scenarios: { ...props.scenarioRun.scenarios, [props.scenarioIdToEdit]: _scenarioToEdit } })
            props.setOpenEditScenarioNameForm(false)
            props.setScenarioId('')
            
        })
    }

    return (
        <>
            <CustomDialog dialogTitle='Edit Scenario Name & Description' setOpenDialog={props.setOpenEditScenarioNameForm} openDialog={props.openEditScenarioNameForm}>
                <form>
                    <div >
                        <label className='title-label'>Name <span className='important'>*</span></label>
                    </div>
                    <div className='col-12'>
                        <CustomTextField size="small" id="outlined-basic" value={scenarioName} onChange={(e) => setScenarioName(e.target.value)} variant="outlined" sx={{ width: '100%' }} /> &nbsp;
                    </div>
                    <br />
                    <div >
                        <label className='title-label'>Description <span className='important'>*</span></label>
                    </div>
                    <div className='col-12'>
                        <CustomTextField size="small" id="outlined-basic" value={scenarioDescription} onChange={(e) => setScenarioDescription(e.target.value)} variant="outlined" sx={{ width: '100%' }} /> &nbsp;
                    </div>
                    <br />
                    <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                        <Button color='secondary' variant="contained" style={{ textTransform: 'none', fontSize: 12, }} onClick={() => updateName()}>Update Edit Scenario Name & Description</Button>
                    </div>
                </form >
            </CustomDialog >

        </>
    )
}

export default EditScenarioName