import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Well } from '../helpers/interfaces';

interface WellsState {
    value: Well[]
}

export const wells = createSlice({
    name: 'wells',
    initialState: {
        value: [],
    } as WellsState,
    reducers: {
        setWells: (state, action: PayloadAction<Well[]>) => {
            state.value = action.payload
        },
    },
})

export const { setWells } = wells.actions;
export default wells.reducer;