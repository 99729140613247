import React, { createContext, useContext, useEffect, useState, memo } from 'react'
import { Scenario, ScenarioRun, Tank } from '../helpers/interfaces'
import ScenarioDetails from '../components/scenarioRun/ScenarioDetails'
import { RoutePathNames, ScenarioRunDisplay, emptyScenario, emptyScenarioRun } from '../helpers/constants'
import ViewScenariosSummaryTable from '../components/scenarioRun/ViewScenariosSummaryTable'
import { useNavigate, useParams } from 'react-router-dom'
import { getAScenarioRun } from '../apiServices/ScenarioRunApi'
import { getTank } from '../apiServices/TankManagemetApi'
import AddScenario from '../components/scenarioRun/AddScenario'
import { ArrowBack } from '@mui/icons-material'
import { Box, IconButton, useTheme, } from '@mui/material'



type ScenarioState = {
    scenario: Scenario
    scenarioId: string
    scenarioRun: ScenarioRun
    setScenario: (c: Scenario) => void
    setScenarioRun: (c: ScenarioRun) => void
}


const ScenarioContext = createContext<ScenarioState>({ scenarioId: '', scenario: emptyScenario, setScenario: (c: Scenario) => { }, scenarioRun: emptyScenarioRun, setScenarioRun: (c: ScenarioRun) => { } })

export const useScenarioContext = () => useContext<ScenarioState>(ScenarioContext)

function ViewScenarioRun() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();
    const [scenarioRun, setScenarioRun] = useState<ScenarioRun>(emptyScenarioRun)
    const [scenario, setScenario] = useState<Scenario>(emptyScenario)
    const [includedTanks, setIncludedTanks] = useState<Tank[]>([]);
    const [scenarioId, setScenarioId] = React.useState('')
    const [scenarioRunDisplay, setScenarioRunDisplay] = useState<ScenarioRunDisplay>(ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE)
    const [previousScenarioRunDisplays, setPreviousScenarioRunDisplays] = useState<ScenarioRunDisplay[]>([])
    const [displayStorageProfileBarChart, setDisplayStorageProfileBarChart] = React.useState(false)
    // const setScenario =useCallback((newScenario:Scenario) => {addScenario({...newScenario})}, []);
    const [excludedTanks, setExcludedTanks] = useState<string>('');
    const [tanks, setTanks] = useState<Tank[]>([]);

   /*  useEffect(() => {
        if (!id) return
        getAScenarioRun(id).then(_scenarioRun => {
            setScenarioRun(_scenarioRun)
            getTank().then(_tanks => {
                const includedTanks = _tanks.filter(e => _scenarioRun.included_tank_ids.find(t => t === e.id) != null)
                setIncludedTanks(includedTanks)
            })
            if (Object.keys(_scenarioRun.scenarios).length > 0)
                setScenarioRunDisplay(ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE)
            else
                setScenarioRunDisplay(ScenarioRunDisplay.ADD_SCENARIO)
        })


    }, [id]) */
   
    useEffect(() => {
        if (!id) return
        getAScenarioRun(id).then(_scenarioRun => {
            setScenarioRun(_scenarioRun)
            if (Object.keys(_scenarioRun.scenarios).length > 0)
                setScenarioRunDisplay(ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE)
            else
                setScenarioRunDisplay(ScenarioRunDisplay.ADD_SCENARIO)
        })
        getTank().then(_tanks => {
            setTanks(_tanks)

        })
        
    }, [id])

    useEffect(() => {
        const excluded_Tanks_arr = tanks.filter(e => scenarioRun.included_tank_ids.find(t => t === e.id) == null)
        const excluded_Tanks = excluded_Tanks_arr.map((e, i) => e.name)
        setExcludedTanks(excluded_Tanks.join(", ")
    )

        const includedTanks = tanks.filter(e => scenarioRun.included_tank_ids.find(t => t === e.id) != null)
        setIncludedTanks(includedTanks)
        console.log(excluded_Tanks)
    }, [tanks, scenarioRun])
    const handleBack = () => {
        setScenarioId('')
        const _scenarioRunDisplay = previousScenarioRunDisplays.pop()
        if (_scenarioRunDisplay == null || scenarioRunDisplay === ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE )
            navigate('/' + RoutePathNames.SCENARIO_RUN_HISTORY)
        else
            setScenarioRunDisplay(_scenarioRunDisplay)
    }

    const handleUpdateScenarioRunDisplay = (_scenarioRunDisplay: ScenarioRunDisplay) => {
        setPreviousScenarioRunDisplays([...previousScenarioRunDisplays, scenarioRunDisplay])
        setScenarioRunDisplay(_scenarioRunDisplay)
    }

    return (
        <ScenarioContext.Provider value={{ scenarioId, scenario, setScenario, scenarioRun, setScenarioRun }}>
            <Box >
                <div className='flex'>
                    <IconButton className='flex flex-row font-bold' onClick={handleBack}><ArrowBack color='inherit' /> </IconButton>
                    <div className='flex flex-row mt-2 font-bold  text-lg' style={{color:theme.palette.text.secondary}}>{scenarioId !== '' ? scenarioRun.scenarios[scenarioId].name + ' ' + scenarioRunDisplay : scenarioRunDisplay}</div>
                </div>
            </Box>

            {scenarioRunDisplay === ScenarioRunDisplay.ADD_SCENARIO &&
                <AddScenario setScenarioRunDisplay={handleUpdateScenarioRunDisplay} includedTanks={includedTanks} />
            }

            {scenarioRunDisplay === ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE &&
                <ViewScenariosSummaryTable excludedTanks={excludedTanks} setScenarioId={setScenarioId} scenarioRunDisplay={scenarioRunDisplay} setScenarioRunDisplay={handleUpdateScenarioRunDisplay} includedTanks={includedTanks} />
            }

            {scenarioRunDisplay === ScenarioRunDisplay.SCENARIO_DETAILS && scenarioId !== '' &&
                <ScenarioDetails excludedTanks={excludedTanks} includedTanks={includedTanks} displayStorageProfileBarChart={displayStorageProfileBarChart} setDisplayStorageProfileBarChart={setDisplayStorageProfileBarChart} />
            }
        </ScenarioContext.Provider>
    )
}

export default memo(ViewScenarioRun)