import { TableCell, TableRow, TextField, tableCellClasses } from '@mui/material';
import { ThemeOptions, createTheme, styled } from '@mui/material/styles';

export const pageBackgroundColorLight = "#F9FAFB";
export const pageBackgroundColorDark = "#292929";
export const infoColorLight = "#0288D1";
export const infoColorDark = "#0052cc";
export const secondaryColorLight = "#cc0000";
export const secondaryColorDark = "#cc5555";
export const primaryTextColorLight = "#000000";
export const primaryTextColorDark = "#ffffff";
export const disabledTextColorLight = "rgba(0, 0, 0, 0.38)";
export const disabledTextColorDark = "rgba(255, 255, 255, 0.5)";
export const primaryBackgroundColorLight = "#ffffff";
export const primaryBackgroundColorDark = "#222222";
export const successColorLight = "#0b9361";
export const successColorDark = "#36B37E";
export const dividerColorLight = "rgba(0, 0, 0, 0.12)";
export const dividerColorDark = "rgba(255, 255, 255, 0.12)";
export const warningColorLight = '#ED7002'
export const headerColor ='#4e4141'
export const subTitle ='#444444'

export function AppTheme(darkMode: boolean) {
    const lightThemeOptions: ThemeOptions = {
        palette: {
            mode: 'light',
            primary: { main: primaryBackgroundColorLight, contrastText: primaryTextColorLight },
            secondary: { main: secondaryColorLight, contrastText: primaryBackgroundColorLight },
            info: { main: infoColorLight, contrastText: primaryBackgroundColorLight },
            action: { selected: secondaryColorLight, focus: secondaryColorLight, },
            success: { main: successColorLight, contrastText: successColorLight },
            warning: { main: warningColorLight, contrastText: warningColorLight },
            background: { default: pageBackgroundColorLight, paper: primaryBackgroundColorLight },
            error: {main: headerColor, contrastText: headerColor   },
            text: { primary: primaryTextColorLight, secondary: subTitle, disabled: disabledTextColorLight, },
            divider: dividerColorLight,
        },
        typography: {
            htmlFontSize: 14,
            fontSize: 13,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        }
    }

    const darkThemeOptions: ThemeOptions = {
        palette: {
            mode: 'dark',
            primary: { main: primaryBackgroundColorDark, contrastText: primaryTextColorDark },
            secondary: { main: secondaryColorDark, contrastText: primaryTextColorDark },
            info: { main: infoColorLight, contrastText: primaryTextColorDark },
            action: { selected: secondaryColorDark, focus: secondaryColorDark /* , active: "#ffffff", hover: "rgba(255, 255, 255, 0.08)", disabled: "rgba(255, 255, 255, 0.3)", disabledBackground: "rgba(255, 255, 255, 0.12)", */ },
            success: { main: successColorDark, contrastText: successColorDark },
            warning: { main: warningColorLight, contrastText: warningColorLight },
            error: {main: primaryTextColorDark, contrastText: infoColorDark   },
            background: { default: pageBackgroundColorDark, paper: primaryBackgroundColorDark },
            text: { primary: primaryTextColorDark, secondary: primaryTextColorDark, disabled: disabledTextColorDark },
          
            divider: dividerColorDark,
        },
        typography: {
            htmlFontSize: 14,
            fontSize: 13,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        }
    }
    return createTheme(darkMode ? darkThemeOptions : lightThemeOptions);
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #cbcbcb !important', 
        
      },
     
    },
  });
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.action.hover : theme.palette.action.hover,
        color:theme.palette.error.main,
        fontSize: 13,
        size:'small',
        fontWeight: 600
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        size:'small',

       
    },
}));