import React, { } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';

interface Props {
    dialogTitle: string
    openDialog: boolean
    children?: React.ReactElement
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>

}
function CustomDialog(props: Props) {
    return (
        <>
            <Dialog fullWidth maxWidth='md' open={props.openDialog} style={{ height: '100%' }} >
                <Stack direction='row' >
                    <DialogTitle style={{fontSize:14, fontWeight:'bolder', width: '50%' }} > {props.dialogTitle}</DialogTitle>
                    <DialogActions style={{ width: '50%' }}>
                         <Button color="inherit" style={{ marginRight: 20 }} onClick={() => props.setOpenDialog(false)}>Close</Button>
                        
                    </DialogActions>
                </Stack>
                <DialogContent>
                    {props.children}
                    <br />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CustomDialog