import React, { useEffect } from 'react'
import { Tank } from '../../helpers/interfaces'
import LineChart from '../linegraph/LineGraph'
import { Box, Paper, useTheme } from '@mui/material'
import moment from 'moment'
import BarChart from '../barchart/BarChart'
import { useScenarioContext } from '../../views/ViewScenarioRun'


interface Props {
    includedTanks: Tank[]
    displayStorageProfileBarChart: boolean
    setDisplayStorageProfileBarChart: React.Dispatch<React.SetStateAction<boolean>>
}

function StorageProfile(props: Props) {
    const { scenarioId, scenarioRun, } = useScenarioContext();
    const [storageProfileSpareCapacityData, setStorageProfileSpareCapacityData] = React.useState<number[]>([])
    const [actualStorageSpareCapacityData, setActualStorageSpareCapacityData] = React.useState<number[]>([])
    const [barStorageProfileSpareCapacityData, setBarStorageProfileSpareCapacityData] = React.useState<number[]>([])
    const [barActualStorageSpareCapacityData, setBarActualStorageSpareCapacityData] = React.useState<number[]>([])
    const [barChartDate, setBarChartDate] = React.useState<string[]>([])
    const theme = useTheme();

    useEffect(() => {
        let scenarioToView = scenarioRun.scenarios[scenarioId]
        let newSpareCapacityArray: number[] = []
        let newTankCapacityArray: number[] = []
        let barSpareCapacityArray: number[] = []
        let barTankCapacityArray: number[] = []
        let barDate: string[] = []
        // console.log(scenarioToView?.storage_profiles)
        scenarioToView?.storage_profiles.forEach((e) => {
            newSpareCapacityArray.push(Math.ceil(e.storage_spare_capacity))
            let tankLevelCapacity = props.includedTanks.reduce((previousSum, b) => {
                let b_capacity = b.tank_level_history?.find(c => c.timestamp === e.date_time)?.storage_spare_capacity ?? 0
                return previousSum + b_capacity

            }, 0)
            newTankCapacityArray.push(Math.ceil(tankLevelCapacity))
            //BarChart
            let date = moment(new Date(e.date_time)).format('MMM D')
            console.log(date)
            if (!barDate.includes(date)) {
                barDate.push(date)
                barSpareCapacityArray.push(Math.ceil(e.storage_spare_capacity))
                barTankCapacityArray.push(Math.ceil(tankLevelCapacity))

            }

        })

        setStorageProfileSpareCapacityData(newSpareCapacityArray)
        setActualStorageSpareCapacityData(newTankCapacityArray)
        setBarStorageProfileSpareCapacityData(barSpareCapacityArray)
        setBarActualStorageSpareCapacityData(barTankCapacityArray)
        setBarChartDate(barDate)
        // eslint-disable-next-line
    }, [scenarioRun])

    let datasets = [
        {
            label: 'Forcasted Storage Profile ',
            data: [...storageProfileSpareCapacityData],
            borderColor: '#DA423F',
        },
        {
            label: 'Actual Storage Profile',
            data: [...actualStorageSpareCapacityData],
            borderColor: '#4283D1',
        },
    ];
    let labels = scenarioRun.scenarios[scenarioId].storage_profiles.map((e) => moment(new Date(e.date_time)).format('MMM D, h:mm a'));

    let barDatasets = [
        {
            label: 'Forcasted Storage Profile ',
            data: [...barStorageProfileSpareCapacityData],
            borderColor: '#DA423F',
        },
        {
            label: 'Actual Storage Profile',
            data: [...barActualStorageSpareCapacityData],
            borderColor: '#4283D1',
        },
    ];



    return (
        <>
            <Box>
                <div className='flex '>
                    <div > Days  &nbsp;<input color='secondary' name="profile" type="radio" checked={!props.displayStorageProfileBarChart} onClick={() => props.setDisplayStorageProfileBarChart(false)} /></div>&nbsp;&nbsp;
                    <div > Hours &nbsp;<input color='info' name="profile" type="radio" checked={props.displayStorageProfileBarChart} onClick={() => props.setDisplayStorageProfileBarChart(true)} /></div>
                </div>
                {props.displayStorageProfileBarChart ?
                    <Paper>
                        <Box mt="10px" p='1.0rem' gap="20px">
                            <Box style={{ textAlign: 'center', color: `${theme.palette.text.primary}`, fontWeight: 'bold' }}>
                                {scenarioRun.scenarios[scenarioId].name} - Storage Profile in Hours
                            </Box>
                            <LineChart datasets={datasets} labels={labels} />

                        </Box>
                    </Paper>
                    :
                    <Paper>

                        <Box mt="10px" p='1.0rem' gap="20px">
                            <Box style={{ textAlign: 'center', color: `${theme.palette.text.primary}`, fontWeight: 'bold' }}>
                                {scenarioRun.scenarios[scenarioId].name}  - Storage Profile in Days
                            </Box>
                            <BarChart datasets={barDatasets} labels={barChartDate} />
                        </Box>

                    </Paper>
                }
            </Box>
        </>
    )
}

export default StorageProfile