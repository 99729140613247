import { useCallback, useEffect, useMemo, useState, } from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AppTheme } from './layouts/theme';
import { PropTSCRoutes } from './routes/PropTSCRoutes';
import { ServerGlobalSettings } from './helpers/interfaces';
import { useAppDispatch, useAppSelector } from './store/store';
import { callServerAPI, callServerRefreshToken, loadAppConfigFromSettingsFile } from './helpers/utils';
import { setServerGlobalSettings } from './store/serverGlobalSettings';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);

function App() {
  const appConfig = useAppSelector(state => state.appConfig.value);
  const theme = useMemo(() => AppTheme(appConfig.DARK_MODE), [appConfig.DARK_MODE])
  const dispatch = useAppDispatch();
  const [pageMounted, setPageMounted] = useState(false);
  const [appConfigLoaded, setAppConfigLoaded] = useState(false);


  const tryRefreshToken = useCallback(async () => {
    await callServerRefreshToken(false)
      .then(function (result) {
        MySwal.close();
        setPageMounted(true)
      })
  }, [])

  useEffect(() => {
    const getServerGlobalSettings = async () => {
      await callServerAPI<ServerGlobalSettings>("GET", '/api/app-config', null, false)
        .then(response => {
          dispatch(setServerGlobalSettings(response.data));
          MySwal.close();
          tryRefreshToken()
        })
    }
    if (appConfigLoaded) getServerGlobalSettings()
  }, [tryRefreshToken, dispatch, appConfigLoaded])

  useEffect(() => {
    const launchLoadAppConfig = async () => {
      await loadAppConfigFromSettingsFile()
      setAppConfigLoaded(true)
    }
    launchLoadAppConfig()
  }, [])




  return (
    <>
      {pageMounted &&
        <div className="app">
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider>
                <PropTSCRoutes />
              </SnackbarProvider>
            </ThemeProvider>
          </Router>
        </div>
      }
    </>
  );
}

export default App;
