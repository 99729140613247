import { Add, Edit, Delete } from '@mui/icons-material'
import { Box, Button, Grid, MenuItem, Pagination, Paper, Stack, Table, TableBody, TableHead, useTheme, } from '@mui/material'
import React from 'react'
import { useState, useEffect } from "react";
import { deleteUserProfile, getAllUserProfiles } from '../apiServices/UserManagementApi';
import { TepngUser } from '../helpers/interfaces';
import CreateUser from '../components/users/CreateUser';
import { FormEditMode } from '../helpers/constants';
import { showConfirmBox } from '../helpers/utils';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../layouts/theme';


function UserList() {
    const theme = useTheme();
    const [users, setUsers] = useState<TepngUser[]>([])
    const [filteredUsers, setFilteredUsers] = useState<TepngUser[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(5);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<TepngUser[]>([])
    const [openUserForm, setOpenUserForm] = React.useState(false);
    const [userEditMode, setUserEditMode] = React.useState(FormEditMode.CREATE);
    const [userToEdit, setUserToEdit] = useState<string>("");
    const handleOpenUserForm = () => {
        setUserEditMode(FormEditMode.CREATE);
        setOpenUserForm(true);
    };
    useEffect(() => {
        getAllUserProfiles().then(users => setUsers(users))
    }, [])
    useEffect(() => {
        setFilteredUsers(users.filter(e =>
            (searchQuery === "" || e.first_name.toLowerCase().includes(searchQuery.toLowerCase()) || e.last_name.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, users])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredUsers.length / recordsPerPage))
    }, [recordsPerPage, filteredUsers])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredUsers.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredUsers])

    const handleEdit = (user: TepngUser) => {
        setUserToEdit(user.id)
        setUserEditMode(FormEditMode.EDIT);
        setOpenUserForm(true);
    }

    const deleteUser = (email: string) => {
        showConfirmBox("Are you sure you want to Delete this User?", " Delete User")
            .then((result) => {
                if (result.isConfirmed)
                    deleteUserProfile(email).then(() => setUsers(users => users.filter(u => u.email !== email)))
            })
    }

    return (
        <Box m="1.0rem 1.0rem">
            <div className=' font-bold text-lg' style={{color:theme.palette.text.secondary}}>User List</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        <div className=" flex flex-row ">
                            <div className=' flex w-2/3 '>
                                <CustomTextField size="small" id="outlined-basic" placeholder="Search by Username..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} variant="outlined" sx={{ width: '50%' }} /> &nbsp;

                            </div>
                            <div style={{ flexGrow: 1 }} />

                            <Button color='secondary' variant="contained" style={{ textTransform: 'none', fontSize: 14, }} onClick={handleOpenUserForm}><Add />&nbsp;Create New User</Button>
                        </div>
                        <br />
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell> SN</StyledTableCell>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Role</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageRecords.map((item, index) => {
                                        return <StyledTableRow key={index}>

                                            <StyledTableCell>{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                            <StyledTableCell>{item.first_name}</StyledTableCell>
                                            <StyledTableCell>{item.last_name}</StyledTableCell>
                                            <StyledTableCell>{item.email}</StyledTableCell>
                                            <StyledTableCell>{item.is_admin === true ? 'Admin' : 'User'}</StyledTableCell>
                                            <StyledTableCell >
                                                <Button variant='outlined' color='info' size='small' onClick={() => { handleEdit(item) }}  ><Edit />&nbsp; Edit</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button variant='outlined' color='secondary' size='small' onClick={() => { deleteUser(item.email) }}   > <Delete />&nbsp; Delete</Button>

                                            </StyledTableCell>
                                        </StyledTableRow>
                                    })}

                                    {currentPageRecords.length === 0 &&
                                        <tr>
                                            <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                        </tr>
                                    }
                                </TableBody>
                            </Table>
                        </div>

                        <br />

                        <nav>
                            <div className=" flex flex-row ">
                                <div className=" flex items-start ">
                                    <div className="px-6 py-2">Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} >
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </CustomTextField>
                                    </Grid>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <Stack spacing={2}>
                                    <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>

                            </div>
                            <br />


                        </nav>

                        {openUserForm &&
                            <CreateUser userToEdit={userToEdit} getAllUserProfiles={getAllUserProfiles} setFormEditMode={setUserEditMode} formEditMode={userEditMode} setUsers={setUsers} users={users} openUserForm={openUserForm} setOpenUserForm={setOpenUserForm} />
                        }
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default UserList