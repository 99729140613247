import React, { memo, useEffect, useState } from 'react'
import { Tank, WellStatuses } from '../../helpers/interfaces'
import { Box, Button, Radio, Paper, Table, TableBody, TableHead, useTheme, } from '@mui/material'
import { emptyScenario, FormEditMode, ScenarioRunDisplay, ScenarioType } from '../../helpers/constants';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import OilReinjectionProfileDefinition from './OilReinjectionProfile';
import CondensateSpikeProfileDefinition from './CondensateSpikeProfile';
import OilWellStatus from './OilWellStatus';
import { generateADHOCScenario, generateOPTIMALScenario } from '../../apiServices/ScenarioApi';
import { formatShortDateString } from '../../helpers/utils';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../../layouts/theme';
import GasWellStatus from './GasWellStatus';
import { useScenarioContext } from '../../views/ViewScenarioRun';

interface Props {
    includedTanks: Tank[]
    setScenarioRunDisplay: (scenarioRunDisplay: ScenarioRunDisplay) => void
}

function AddScenario(props: Props) {
    const theme = useTheme();
    const [scenarioFormStep, setScenarioFormStep] = useState<number>(0);
    const [tanksIncluded, setTanksIncluded] = useState<string>('')
    const { scenario, setScenario, scenarioRun, setScenarioRun } = useScenarioContext();

    useEffect(() => {

        let wellStatuses: WellStatuses = {}
        scenarioRun.wells.forEach((e) => {
            wellStatuses[e.name] = e.status
        })
        setScenario({ ...scenario, well_statuses: wellStatuses })
        //eslint-disable-next-line
    },[])

    useEffect(()=> {
    
        const included_Tanks = props.includedTanks.map((e, i) => e.name)
        setTanksIncluded(included_Tanks.join(", "))
       }, [props.includedTanks])
    
    const updateFieldValue = (name: string, value: any) => {
        setScenario({ ...scenario, [name]: value })
        console.log(scenario)
    }

    function handleNext() {
        setScenarioFormStep(scenarioFormStep + 1)
    }
    function handleBack() {
        setScenarioFormStep(scenarioFormStep - 1)
    }

    function handleGenerateAdhocScenario() {
        generateADHOCScenario(scenarioRun.id, scenario).then((_scenarioRun) => {
            setScenarioRun(_scenarioRun)

            props.setScenarioRunDisplay(ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE)
            setScenario(emptyScenario)
        })
    }

    function handleGenerateOptimalScenario() {
        generateOPTIMALScenario(scenarioRun.id, scenario).then((_scenarioRun) => {
            setScenarioRun(_scenarioRun)
            props.setScenarioRunDisplay(ScenarioRunDisplay.VIEW_SCENARIOS_SUMMARY_TABLE)
            setScenario(emptyScenario)
        })
    }
    function handleWellStatusChange(wellstatuses: WellStatuses) {
        console.log(scenario)
        setScenario({ ...scenario, well_statuses: { ...scenario.well_statuses, ...wellstatuses } })
    }

    return (
        <Box  >
            <Box mt="10px" p='1.0rem' gap="20px">
                <Paper sx={{ padding: '1.0rem' }}>
                    <div>

                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" style={{ border: `1px solid ${theme.palette.action.hover}` }}>

<TableHead >
    <StyledTableRow> <StyledTableCell colSpan={8} style={{ color: theme.palette.text.primary }}> Scenario Run Parameters  {scenarioRun.test_run ? '- Test Run':' '}</StyledTableCell>
    </StyledTableRow>
    <StyledTableRow>
        <StyledTableCell > Implementation Start</StyledTableCell>
        <StyledTableCell> Last Refresh Date</StyledTableCell>
        <StyledTableCell > Included Tanks</StyledTableCell>
        <StyledTableCell > Min. LP Compressors to run</StyledTableCell>
        <StyledTableCell > Min. MP Compressors to run</StyledTableCell>
        <StyledTableCell  > Min. HP Compressors to run</StyledTableCell>
        <StyledTableCell> Gas for Internal Use/Flaring</StyledTableCell>
        <StyledTableCell >Max. Condensate Oil Ratio </StyledTableCell>
    </StyledTableRow>
</TableHead>
<TableBody>
    <StyledTableRow >
        <StyledTableCell >{formatShortDateString(scenarioRun.implementation_start_date, true)}</StyledTableCell>
        <StyledTableCell  >{formatShortDateString(scenarioRun.last_refresh_date, true)}</StyledTableCell>
        <StyledTableCell style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }} >

            <div>
                <span>{tanksIncluded.substring(0, tanksIncluded.length)}</span>
            </div>
        </StyledTableCell>
        <StyledTableCell >{scenarioRun.min_no_lp_compressors_to_run} LP ({scenarioRun.min_no_lp_compressors_to_run === 2 ? scenarioRun.compressor_settings.min_daily_gas_production_to_run_2_lp : scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_lp * scenarioRun.min_no_lp_compressors_to_run} Ksm3/d)</StyledTableCell>
        <StyledTableCell >{scenarioRun.min_no_mp_compressors_to_run} MP ({scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_mp * scenarioRun.min_no_mp_compressors_to_run} Ksm3/d)</StyledTableCell>
        <StyledTableCell >{scenarioRun.min_no_hp_compressors_to_run} HP ({scenarioRun.compressor_settings.min_daily_gas_production_to_run_1_hp * scenarioRun.min_no_hp_compressors_to_run} Ksm3/d)</StyledTableCell>
        <StyledTableCell >{scenarioRun.gas_for_internal_use_and_flare}</StyledTableCell>
        <StyledTableCell >{scenarioRun.maximum_condensate_oil_ratio}%</StyledTableCell>
    </StyledTableRow>
</TableBody>
</Table>
                    </div>
                </Paper>
            </Box>
            <Box mt="10px" p='1.0rem' gap="20px">
                <div>
                    <div className='flex'>
                        <p className=' font-bold flex flex-col text-lg'>
                            {scenario.scenario_type === ScenarioType.ADHOC ? 'Ad-hoc Scenario Generation' : ''}
                            {scenario.scenario_type === ScenarioType.OPTIMAL ? 'Optimal Scenario Generation' : ''}
                            {scenario.scenario_type === '' ? 'Scenario Details' : ''}
                        </p>
                        <div style={{ flexGrow: 1 }} />
                        <div className=' font-bold flex flex-row '>

                            <Button color='info' variant="contained" disabled={scenarioFormStep === 0} onClick={handleBack} ><ArrowBackIosNew />&nbsp;Back</Button> &nbsp;&nbsp;
                            <Button color='info' variant="contained" onClick={handleNext} disabled={scenarioFormStep === 2 || scenario.scenario_type === ''}>Next&nbsp;<ArrowForwardIos /></Button>&nbsp;&nbsp;
                            <Button color='secondary' variant="contained" disabled={scenarioFormStep !== 2} onClick={scenario.scenario_type === ScenarioType.ADHOC ? handleGenerateAdhocScenario : handleGenerateOptimalScenario} >Generate Scenario</Button>
                        </div>

                    </div>

                    {scenarioFormStep === 0 &&
                        <>
                            <br />
                            <Paper sx={{ padding: '1.0rem' }}>
                                <Table>
                                    <TableHead>
                                        <StyledTableRow><StyledTableCell style={{ color: theme.palette.text.primary }}>Scenario Generation Type</StyledTableCell></StyledTableRow>
                                    </TableHead>
                                    <TableBody style={{ fontSize: '12px' }}>
                                        <StyledTableRow >
                                            <StyledTableCell className='' > <Radio color='secondary' value={ScenarioType.ADHOC} checked={scenario.scenario_type === ScenarioType.ADHOC} id='scenario_type' onChange={(e) => updateFieldValue('scenario_type', e.target.value)} />Ad hoc Scenario Generation</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell > <Radio color='secondary' value={ScenarioType.OPTIMAL} checked={scenario.scenario_type === ScenarioType.OPTIMAL} id='scenario_type' onChange={(e) => updateFieldValue('scenario_type', e.target.value)} /> Optimal Scenario Generation </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    }
                    {scenarioFormStep === 1 &&
                        <>
                            <br />
                            <Paper sx={{ padding: '1.0rem' }}>
                                <div className='flex'>
                                    <div className='relative w-1/2  flex flex-col '>
                                        < OilReinjectionProfileDefinition formEditMode={FormEditMode.CREATE} privateScenario={scenario} setPrivateScenario={setScenario} />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className='w-1/2 flex flex-col'>
                                        < CondensateSpikeProfileDefinition formEditMode={FormEditMode.CREATE} privateScenario={scenario} setPrivateScenario={setScenario} />
                                    </div>

                                    <br />
                                </div>
                            </Paper>
                        </>
                    }


                    {scenarioFormStep === 2 &&
                        <>
                            <br />
                            {scenario.scenario_type === ScenarioType.ADHOC &&
                                <>
                                    <div className='flex'>
                                        <div className='relative w-1/2  flex flex-col '>
                                            <Paper sx={{ padding: '1.0rem' }}>
                                                <OilWellStatus formEditMode={FormEditMode.CREATE} handleWellStatusChange={handleWellStatusChange} />
                                            </Paper>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='relative w-1/2  flex flex-col '>
                                            <Paper sx={{ padding: '1.0rem' }}>
                                                <GasWellStatus formEditMode={FormEditMode.CREATE} handleWellStatusChange={handleWellStatusChange} />
                                            </Paper>
                                        </div>
                                    </div>
                                </>
                            }
                            {scenario.scenario_type === ScenarioType.OPTIMAL &&
                                <Paper sx={{ padding: '1.0rem' }}>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Minimum Autonomy Hours </label>
                                        </div>
                                        <div className=''>
                                            <CustomTextField fullWidth size="small" id='min_autonomy_hours' placeholder="Minimum Autonomy Hours " onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={scenario.min_autonomy_hours} />
                                        </div>
                                    </div>
                                </Paper>
                            }

                        </>
                    }


                </div>
            </Box>

        </Box >
    )
}

export default memo(AddScenario)