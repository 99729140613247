import { enqueueSnackbar } from "notistack";
import { Tank, ServerAPIResponse } from "../helpers/interfaces";
import { showBackDrop, callServerAPI } from '../helpers/utils'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllTankSummaries = async () => {
    return await new Promise<Tank[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading All Tanks"));
        callServerAPI<ServerAPIResponse<Tank[]>>('GET', '/api/tank/get-tank-summaries', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export const getTank = async () => {
    return await new Promise<Tank[]>(async (resolve, reject) => {
        callServerAPI<ServerAPIResponse<Tank[]>>('GET', '/api/tank/get-tanks', null, true)
            .then(response => {
                resolve(response.data.data)
            })
    })
}

export const UpdateTank = async (id: string, tankData: Tank) => {

    return await new Promise<Tank[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Tank"));
        callServerAPI<ServerAPIResponse<Tank[]>>('PATCH', `/api/tank/update-tank?id=${id}`, tankData, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Tank  Updated", { variant: 'success' });

            })
    })
}

export const CreateTank = async (tankData: Tank) => {

    return await new Promise<Tank[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating New Tank"));
        callServerAPI<ServerAPIResponse<Tank[]>>('POST', '/api/tank/add-tank', tankData, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Tank  Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}