import React, { memo, useState } from 'react'

import { emptyScenario, FormEditMode } from '../../../helpers/constants';
import CondensateSpikeProfileDefinition from '../CondensateSpikeProfile';
import { Scenario } from '../../../helpers/interfaces';
import { useScenarioContext } from '../../../views/ViewScenarioRun';
import OverviewCustomDialog from '../../CustomDialogueOverview';

interface Props {
    updateScenario:(scenario: Scenario) => void
    openCondensateProfile: boolean
    setOpenCondensateProfile: React.Dispatch<React.SetStateAction<boolean>>
}
function CondensateSpikeKPI(props:Props) {
    const [privateScenario, setPrivateScenario] = useState<Scenario>(emptyScenario)
    const {  setScenario } = useScenarioContext();
    function update(){
      props.updateScenario(privateScenario)
      props.setOpenCondensateProfile(false)
      setPrivateScenario(emptyScenario)
      setScenario(emptyScenario)
    }
    return (
      <>
      <OverviewCustomDialog dialogTitle={ 'Update Condensate Spike Profile Event '} setOpenDialog={props.setOpenCondensateProfile} openDialog={props.openCondensateProfile} buttonTitle='Update Condensate Spike Profile Event' buttonFunction={update} >
          <form>
          < CondensateSpikeProfileDefinition formEditMode={FormEditMode.EDIT}  privateScenario={privateScenario} setPrivateScenario={setPrivateScenario}/>
             
          </form >
      </OverviewCustomDialog>
  
  </>
  )
}

export default memo(CondensateSpikeKPI)