import React, { useState, useEffect } from 'react'
import { Tank } from '../../helpers/interfaces'
import CustomDialog from '../CustomDialogue'
import { FormEditMode, TankType, emptyTank } from '../../helpers/constants'
import { Button, MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'
import { UpdateTank, CreateTank } from '../../apiServices/TankManagemetApi'
import { CustomTextField } from '../../layouts/theme'

const tankTypeOptions = [{ value: TankType.STORAGE_TANK, label: 'Storage Tank' }, { value: TankType.WASH_TANK, label: 'Wash Tank' }]

interface Props {
  getAllTanks: () => Promise<Tank[]>
  setFormEditMode: React.Dispatch<React.SetStateAction<number>>
  formEditMode: number
  setTanks: React.Dispatch<React.SetStateAction<Tank[]>>
  tanks: Tank[]
  tankToEdit: string
  openTankForm: boolean
  setOpenTankForm: React.Dispatch<React.SetStateAction<boolean>>

}

function CreateEditTanks(props: Props) {
  const [tankItem, setTankItem] = useState<Tank>(emptyTank);

  useEffect(() => {
    if (props.formEditMode === FormEditMode.EDIT) {
      var tank = props.tanks.find(e => e.id === props.tankToEdit)
      if (!tank) return
      setTankItem(tank)
    }
  }, [props.formEditMode, props.tankToEdit, props.tanks])

  function createNewTank() {
    return new Promise<void>((resolve, reject) => {
      CreateTank(tankItem).then(result => {
        props.getAllTanks().then(tank => props.setTanks(tank))
        resolve()
      })
      props.setOpenTankForm(false);
    })
  }

  function updateTank() {
    console.log('anything')
    return new Promise<void>((resolve, reject) => {
      console.log('something')
      UpdateTank(tankItem.id, tankItem)
       var _tanks = props.tanks.map(e=> e.id === tankItem.id ? tankItem: e)
       console.log(_tanks)
       props.setTanks(_tanks)
    
      props.setOpenTankForm(false);
    })
  }
  const updateFieldValue = (name: string, value: any) => {
    setTankItem(previoustankItem => ({ ...previoustankItem, [name]: value }))
  }

  return (
    props.tanks && tankItem &&
    <>
      <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? 'Add New Tank ' : 'Update Tank '} setOpenDialog={props.setOpenTankForm} openDialog={props.openTankForm}>
        <form>

          <div className='row'>
            <div className='col1'>
              <div >
                <div >
                  <label className=' title-label'>Tank Name </label>
                </div>
                <div className=''>
                  <CustomTextField fullWidth size="small" id='name' placeholder="Tank Name" onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.name} />
                </div>
              </div>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className='col2'>

              <div  >
                <div >
                  <label className='title-label'>Tank Type</label>
                </div>
                <div className=''>
                  <div className="form-group mb-0">

                    <CustomTextField fullWidth size="small" select variant="outlined" id="type" onChange={(e) => { updateFieldValue('type', e.target.value) }} value={tankItem.type}
                    >
                      {tankTypeOptions.map((tankType, index) => {
                        return <MenuItem key={index} value={tankType.value}>{tankType.label}</MenuItem >
                      })}
                    </CustomTextField>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col1'>
              <div >
                <div >
                  <label className=' title-label'>Tank Capacity </label>
                </div>
                <div className=''>
                  <CustomTextField type="number" fullWidth size="small" id='capacity' placeholder="Tank Capacity" onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.capacity} />
                </div>
              </div>
            </div> &nbsp;&nbsp;&nbsp;
            <div className='col2'>

              <div  >
                <div >
                  <label className='title-label'>Volume per height of tank (bbls/mm)</label>
                </div>

                <div className=''>
                  <CustomTextField fullWidth type='number' size="small" id='volume_per_height' placeholder="Volume per height of tank" onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.volume_per_height} />
                </div>

              </div>
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col1'>
              <div >
                <label className='title-label'>Tank Level PI Tag </label>
              </div>

              <div className=''>
                <CustomTextField fullWidth size="small" id='tank_level_pi_tag' placeholder="Tank Level PI Tag " onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.tank_level_pi_tag} />
              </div>

            </div>&nbsp;&nbsp;&nbsp;

            <div className='col2'>

              <div  >
                <div >
                  <label className='title-label'>Level Alarm High (%) </label>
                </div>

                <div className=''>
                  <CustomTextField fullWidth size="small" id='lah' placeholder="Level Alarm High (%) " onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.lah} />
                </div>

              </div>
            </div>
          </div>
          <br />
          {tankItem.type === TankType.WASH_TANK &&
          <>
          
          <div className='row'>
            <div className='col1'>
              <div >
                <div >
                  <label className=' title-label'>Maximum Oil Thickness (m) </label>
                </div>
                <div className=''>
                  <CustomTextField fullWidth type='number' size="small" id='max_oil_thickness' placeholder="Maximum Oil Thickness (m)" onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.max_oil_thickness} />
                </div>
              </div>
            </div> &nbsp;&nbsp;&nbsp;
            <div className='col2'>

              <div  >
                <div >
                  <label className='title-label'>Oil Thickness PI Tag</label>
                </div>

                <div className=''>
                  <CustomTextField fullWidth size="small" id='oil_thickness_pi_tag' placeholder="Oil Thickness PI Tag" onChange={(e) => updateFieldValue(e.target.id, e.target.value)} value={tankItem.oil_thickness_pi_tag} />
                </div>

              </div>
            </div>
          </div>
          </>
          }
          <br />
          <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
            <Button variant="contained" color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? createNewTank : updateTank} >
              <Add />&nbsp; {props.formEditMode === FormEditMode.CREATE ? 'Add Tank' : 'Update Tank'}
            </Button>
          </div>
        </form>


      </CustomDialog>

    </>
  )
}

export default CreateEditTanks